import { useRef, useEffect } from 'react';
import * as workerTimers from 'worker-timers';

type Props = {
  callback: () => void;
  intervalDelay: number;
  shouldRun: boolean;
};

function useInterval(props: Props) {
  const { callback, intervalDelay, shouldRun } = props;

  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }

    if (shouldRun) {
      const intervalId = workerTimers.setInterval(tick, intervalDelay);

      return () => workerTimers.clearInterval(intervalId);
    }
  });
}

export default useInterval;
