import styled from '@emotion/styled/macro';
import React from 'react';
import tw from 'twin.macro';

import bgBlueCenterLogo from '../assets/bg_blue_center_logo.png';
import Header from './Header';

type Props = {
  children: React.ReactNode;
  withHeader?: boolean;
  flexibleHeight?: boolean;
};

export default function Layout({ children, withHeader, flexibleHeight }: Props) {
  return (
    <StyledScreenContainer>
      <StyledContentContainer className={flexibleHeight ? 'min-h-112' : 'h-112'}>
        {withHeader && <Header />}
        <div className="justify-center h-full items-center flex flex-1 flex-col">{children}</div>
      </StyledContentContainer>
    </StyledScreenContainer>
  );
}

const StyledScreenContainer = styled.div`
  ${tw`
    bg-cool-gray-900 
    flex 
    flex-1
    items-center 
    justify-center
  `}
`;

const StyledContentContainer = styled.div`
  background-image: url(${bgBlueCenterLogo});
  background-position: center;

  ${tw`flex bg-cover lg:border-8 border-white max-w-4xl max-h-screen w-full flex-col`}
`;
