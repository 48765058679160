import jwt from 'jsonwebtoken';

type AuthPayload = {
  sub: string;
  role: string;
  'https://hasura.io/jwt/claims': {
    'x-hasura-allowed-roles': string[];
    'x-hasura-default-role': 'anonymous';
    'x-hasura-user-id': string;
  };
};

export function verifyAuthContext(): {
  accessToken: string;
  role: string;
  userId: string;
} | null {
  const accessToken = localStorage.getItem('ACCESS_TOKEN');

  if (!accessToken || !process.env.REACT_APP_JWT_KEY) {
    return null;
  }

  const payload = jwt.verify(accessToken, process.env.REACT_APP_JWT_KEY) as AuthPayload;

  return {
    accessToken,
    userId: payload.sub,
    role: payload.role,
  };
}

export function resetAuthContext() {
  localStorage.removeItem('ACCESS_TOKEN');
}
