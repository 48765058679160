import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Button from '../../components/Button';
import Title from '../../components/Title';
import { POINTS_TO_WIN_PRIZES } from '../../config/constants';
import Layout from '../../layouts';

function AwaitQuestionsPage() {
  const history = useHistory();
  const location = useLocation();

  return (
    <Layout>
      <Title>READY to REACT?</Title>

      <p className="text-2xl lg:text-3xl text-center my-4 px-8 md:px-20">
        <b>5</b> Weekly Players with <b>{POINTS_TO_WIN_PRIZES}+</b> points will win big prizes.
      </p>

      <Button title="PLAY" handleClick={handleNavigation} />
    </Layout>
  );

  function handleNavigation() {
    history.push(`/questions`, location.state);
  }
}

export default AwaitQuestionsPage;
