import gql from 'graphql-tag';

// Fragments

export const VideoBaseFragment = gql`
  fragment VideoBaseFragment on videos {
    id
    url
  }
`;

//  Queries

export const VIDEO = gql`
  query video($id: Int!) {
    videos_by_pk(id: $id) {
      ...VideoBaseFragment
    }
  }
  ${VideoBaseFragment}
`;

export const VIDEO_REPORT = gql`
  query videoReport($id: Int!, $startDatetime: timestamptz, $endDatetime: timestamptz) {
    highScores: answered_question_set_high_score(
      where: { video_id: { _eq: $id }, created_at: { _gte: $startDatetime, _lte: $endDatetime } }
    ) {
      high_score
      first_name
      email
    }
    acceptedOffers: answered_questions(
      where: {
        question: { type: { _eq: OFFER } }
        answer: { is_correct: { _eq: true } }
        video_id: { _eq: $id }
        created_at: { _gte: $startDatetime, _lte: $endDatetime }
      }
    ) {
      id
      created_at
      user {
        id
        first_name
        email
      }
    }

    videos_by_pk(id: $id) {
      ...VideoBaseFragment
      totalQuestionsAnswered: answered_questions_aggregate(
        where: { created_at: { _gte: $startDatetime, _lte: $endDatetime } }
      ) {
        aggregate {
          count
        }
      }
      video_views_aggregate(where: { created_at: { _gte: $startDatetime, _lte: $endDatetime } }) {
        aggregate {
          count
        }
      }
      totalCorrectQuestionsAnswered: answered_questions_aggregate(
        where: { answer: { is_correct: { _eq: true } }, created_at: { _gte: $startDatetime, _lte: $endDatetime } }
      ) {
        aggregate {
          count
        }
      }
      totalUnanswered: answered_questions_aggregate(
        where: { answer_id: { _is_null: true }, created_at: { _gte: $startDatetime, _lte: $endDatetime } }
      ) {
        aggregate {
          count
        }
      }
      video_ratings(where: { created_at: { _gte: $startDatetime, _lte: $endDatetime } }) {
        id
        created_at
        value
      }
      questions {
        id
        text
        type
        totalUnansweredCount: answered_questions_aggregate(
          where: { answer_id: { _is_null: true }, created_at: { _gte: $startDatetime, _lte: $endDatetime } }
        ) {
          aggregate {
            count
          }
        }
        totalAnsweredCount: answered_questions_aggregate(
          where: { answer_id: { _is_null: false }, created_at: { _gte: $startDatetime, _lte: $endDatetime } }
        ) {
          aggregate {
            count
          }
        }
        correctAnsweredCount: answered_questions_aggregate(
          where: { answer: { is_correct: { _eq: true } }, created_at: { _gte: $startDatetime, _lte: $endDatetime } }
        ) {
          aggregate {
            count
          }
        }
        answers {
          id
          text
          is_correct
          totalAnsweredCount: answered_questions_aggregate(
            where: { created_at: { _gte: $startDatetime, _lte: $endDatetime } }
          ) {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
  ${VideoBaseFragment}
`;

// Mutations

export const CREATE_VIDEO_VIEW = gql`
  mutation createVideoView($videoId: Int!) {
    insert_video_views_one(object: { video_id: $videoId }) {
      id
    }
  }
  ${VideoBaseFragment}
`;
