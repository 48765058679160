import React from 'react';
import { useHistory } from 'react-router-dom';

import { client } from '../config/apolloClient';
import { verifyAuthContext } from '../libs/authentication';
import { User_Roles_Enum } from '../types/graphql-generated';

function Header() {
  const authContext = verifyAuthContext();
  const history = useHistory();

  const profileDropdownRef = React.useRef<HTMLDivElement>(null);
  const mobileMenuDropdownRef = React.useRef<HTMLDivElement>(null);
  const mobileMenuButtonRef = React.useRef<HTMLDivElement>(null);

  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <nav style={{ backgroundColor: '#ce0028' }} className="shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-10">
          <div className="flex">
            <div className="hidden sm:ml-6 sm:flex">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://react.net/"
                className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-md font-medium leading-5 text-white"
              >
                About React
              </a>
            </div>
            <div className="hidden sm:ml-6 sm:flex">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://react.net/"
                className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-md font-medium leading-5 text-white"
              >
                Official Rules
              </a>
            </div>
            <div className="hidden sm:ml-6 sm:flex">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://react.net/contact-us/"
                className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-md font-medium leading-5 text-white"
              >
                Contact Us
              </a>
            </div>
          </div>

          {authContext && authContext.role !== User_Roles_Enum.UNKNOWN_USER && (
            <div className="hidden sm:ml-6 sm:flex sm:items-center">
              <div className="flex-1 px-4 flex justify-end">
                <div className="ml-4 flex items-center md:ml-6">
                  <div className="ml-3 relative">
                    <div ref={profileDropdownRef}>
                      <button
                        onClick={() => setIsDropdownMenuOpen(!isDropdownMenuOpen)}
                        className="max-w-xs flex items-center focus:outline-none"
                      >
                        <div className="flex items-center">
                          <svg
                            className="text-gray-800 mr-1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </button>

                      {isDropdownMenuOpen && (
                        <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-50">
                          <div className="py-1 rounded-md bg-white shadow-xs ">
                            <div
                              onClick={signOut}
                              className="block cursor-pointer z-50 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150"
                            >
                              Sign out
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div ref={mobileMenuButtonRef} className="-mr-2 flex items-center sm:hidden">
            <button
              onClick={() => setIsDropdownMenuOpen(!isDropdownMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
            >
              <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path
                  className={`${isDropdownMenuOpen ? 'hidden' : 'inline-flex'}`}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
                <path
                  className={`${isDropdownMenuOpen ? 'inline-flex' : 'hidden'}`}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div ref={mobileMenuDropdownRef} className={`sm:hidden ${isDropdownMenuOpen ? 'block' : 'hidden'}`}>
        <div className="pt-2 pb-3">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://react.net"
            className="block pl-3 pr-4 py-2 text-base border-l-4 font-medium text-gray-600"
          >
            About React
          </a>
        </div>
        <div className="pt-2 pb-3">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://react.net"
            className="block pl-3 pr-4 py-2 text-base border-l-4 font-medium text-gray-600"
          >
            Official rules
          </a>
        </div>
        <div className="pt-2 pb-3">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://react.net/contact-react/"
            className="block pl-3 pr-4 py-2 text-base border-l-4 font-medium text-gray-600"
          >
            Contact Us
          </a>
        </div>

        {authContext && authContext.role !== User_Roles_Enum.UNKNOWN_USER && (
          <div className="pt-4 pb-3 border-t border-gray-200">
            <div className="flex items-center px-4">
              <svg
                className="text-gray-600"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="mt-3">
              <div
                onClick={signOut}
                className="mt-1 cursor-pointer block px-4 py-2 text-base font-medium text-red-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out"
              >
                Sign out
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );

  function handleClick(event: any) {
    if (
      (profileDropdownRef && profileDropdownRef.current && profileDropdownRef.current.contains(event.target)) ||
      (mobileMenuDropdownRef &&
        mobileMenuDropdownRef.current &&
        mobileMenuDropdownRef.current.contains(event.target)) ||
      (mobileMenuButtonRef && mobileMenuButtonRef.current && mobileMenuButtonRef.current.contains(event.target))
    ) {
      return;
    }

    setIsDropdownMenuOpen(false);
  }

  function signOut() {
    localStorage.removeItem('ACCESS_TOKEN');
    sessionStorage.clear();
    client.clearStore();

    history.push('/');
  }
}

export default Header;
