export function calculatePercentage(selection: number, total: number) {
  if (!selection || !total) {
    return 0;
  }

  return Math.round(((selection / total) * 100 + Number.EPSILON) * 100) / 100;
}

export function calculatePercentileRank(ranksBelow: number, ranksEqual: number, ranksTotal: number) {
  if (!ranksBelow || !ranksTotal) {
    return 0;
  }
}

export function shuffle<T>(array: T[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];

    array[i] = array[j];
    array[j] = temp;
  }

  return array;
}
