import React from 'react';
import { useHistory } from 'react-router';

import Button from '../../components/Button';
import Layout from '../../layouts';

function OnboardingPage() {
  const history = useHistory();

  return (
    <Layout>
      <h3 className="text-gray-900 font-semibold font-poppins mt-3 mb-3 text-3xl">Want to know how you did?</h3>

      <Button handleClick={navigateToRegistrationPage} title="Register"></Button>
      <Button handleClick={navigateToLoginPage} title="Log In"></Button>
    </Layout>
  );

  function navigateToRegistrationPage() {
    history.push('/register');
  }

  function navigateToLoginPage() {
    history.push('/login');
  }
}

export default OnboardingPage;
