import styled from '@emotion/styled';
import React from 'react';
import tw from 'twin.macro';

type Props = {
  children: React.ReactNode;
};

export default function Subtitle(props: Props) {
  return <StyledSubtitle>{props.children}</StyledSubtitle>;
}

const StyledSubtitle = styled.h4`
  color: #ffffff;

  ${tw`
    font-poppins
    font-semibold
    italic 
    text-2xl
    lg:text-4xl
  `}
`;
