import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import { CSVLink } from 'react-csv';

import { ANSWERED_QUESTION_SET_SCORE } from '../api/answeredQuestionSets';
import { AnsweredQuestionSetScoreQuery, AnsweredQuestionSetScoreQueryVariables } from '../types/graphql-generated';

interface Props {
  startDatetime?: Date;
  endDatetime?: Date;
}

export default function ExportScoresButton({ startDatetime, endDatetime }: Props) {
  const { data } = useQuery<AnsweredQuestionSetScoreQuery, AnsweredQuestionSetScoreQueryVariables>(
    ANSWERED_QUESTION_SET_SCORE,
    {
      variables: { startDatetime, endDatetime },
    },
  );

  const entries = filterEntries(data);

  function filterEntries(result: AnsweredQuestionSetScoreQuery | undefined) {
    if (!result?.answered_question_set_score) {
      return [];
    }

    return result.answered_question_set_score.map(({ __typename, ...attributes }) => attributes);
  }

  function processDownload() {
    if (entries.length == 0) {
      alert('No results for the selected period.');
      return false;
    }

    return true;
  }

  return (
    <CSVLink
      data={entries}
      onClick={processDownload}
      filename={`scores-${formatDateToFilename(startDatetime)}-${formatDateToFilename(endDatetime)}`}
      className="font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 transition ease-in-out px-4 py-2"
    >
      Export Scores
    </CSVLink>
  );
}

function formatDateToFilename(date?: Date) {
  return date?.toJSON().slice(0, 10);
}
