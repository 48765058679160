import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React from 'react';
import { useHistory } from 'react-router';

import Button from '../../components/Button';
import Subtitle from '../../components/Subtitle';
import Title from '../../components/Title';
import withOrientationGuard from '../../hocs/withOrientationGuard';
import Layout from '../../layouts';
import { verifyAuthContext } from '../../libs/authentication';
import { CreateUnknownUserMutation } from '../../types/graphql-generated';

function VideoGatewayPage() {
  const [hasSeenVideo, setHasSeenVideo] = React.useState(false);

  const history = useHistory();

  const authContext = verifyAuthContext();

  const [createUnknownUser] = useMutation<CreateUnknownUserMutation>(CREATE_UNKNOWN_USER);

  return (
    <Layout>
      <Title>SELECT YOUR VERSION</Title>
      <Subtitle>Have you already seen the video?</Subtitle>

      <fieldset className="mt-6">
        <div className="mb-4">
          <div className="flex items-center">
            <input
              id="push_everything"
              name="form-input push_notifications"
              type="radio"
              checked={!hasSeenVideo}
              onChange={() => setHasSeenVideo(false)}
              className="form-radio h-4 w-4 text-black transition duration-150 ease-in-out"
            />
            <label htmlFor="push_everything" className="ml-3">
              <span className="block text-sm leading-5 font-semibold text-2xl font-poppins text-white">
                No, show me now!
              </span>
            </label>
          </div>

          <div className="mt-6 flex items-center">
            <input
              id="push_email"
              name="form-input push_notifications"
              type="radio"
              checked={hasSeenVideo}
              onChange={() => setHasSeenVideo(true)}
              className="form-radio h-4 w-4 text-black transition duration-150 ease-in-out"
            />
            <label htmlFor="push_email" className="ml-3">
              <span className="block text-sm leading-5 font-semibold text-2xl font-poppins text-white">
                Yes, I’m ready to React!
              </span>
            </label>
          </div>
        </div>
      </fieldset>
      <Button handleClick={handleNavigation} title="NEXT" />
    </Layout>
  );

  async function handleNavigation() {
    try {
      if (!authContext) {
        const mutationResult = await createUnknownUser();

        if (mutationResult.data) {
          const { accessToken } = mutationResult.data.createUnknownUser;

          localStorage.setItem('ACCESS_TOKEN', accessToken);
        }
      }

      if (hasSeenVideo) {
        history.push('/rate_video');
      } else {
        history.push('/watch_video');
      }
    } catch (err) {
      console.log('ERROR', err);
    }
  }
}

const CREATE_UNKNOWN_USER = gql`
  mutation createUnknownUser {
    createUnknownUser {
      __typename
      accessToken
    }
  }
`;

export default withOrientationGuard(VideoGatewayPage);
