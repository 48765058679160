import { useMutation, useQuery } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import React, { useContext, useRef } from 'react';
import { useHistory } from 'react-router';
import tw from 'twin.macro';

import { CREATE_VIDEO_VIEW, VIDEO } from '../../api/videos';
import { QuizContext } from '../../context/quizContext';
import withOrientationGuard from '../../hocs/withOrientationGuard';
import useVideoPlayer from '../../hooks/useVideoPlayer';
import Layout from '../../layouts';
import {
  CreateVideoViewMutation,
  CreateVideoViewMutationVariables,
  VideoQuery,
  VideoQueryVariables,
} from '../../types/graphql-generated';

function WatchVideoPage() {
  const history = useHistory();
  const [cachedVideo, setVideo] = useContext(QuizContext);

  const { data, error } = useQuery<VideoQuery, VideoQueryVariables>(VIDEO, { variables: { id: 1 } });
  const [createVideoView] = useMutation<CreateVideoViewMutation, CreateVideoViewMutationVariables>(CREATE_VIDEO_VIEW);

  const videoRef = useRef<HTMLVideoElement>(null);
  const { isPlaying, togglePlay } = useVideoPlayer(videoRef);

  const video = data?.videos_by_pk;

  React.useEffect(() => {
    if (!cachedVideo && video) {
      setVideo(video);
    }
  }, [cachedVideo, video]);

  if (error) {
    return <div>Something went wrong</div>;
  }

  return (
    <Layout>
      {video && (
        <div className="relative h-full cursor-pointer">
          {!isPlaying && (
            <VideoControls>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="cursor-pointer w-24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM10.622 8.415a.4.4 0 0 0-.622.332v6.506a.4.4 0 0 0 .622.332l4.879-3.252a.4.4 0 0 0 0-.666l-4.88-3.252z" />
              </svg>
            </VideoControls>
          )}

          <video ref={videoRef} className="h-full" onEnded={handleVideoEnded} onClick={togglePlay}>
            <source src={video.url} type="video/mp4" />
          </video>
        </div>
      )}
    </Layout>
  );

  async function handleVideoEnded() {
    if (!video) {
      return;
    }

    try {
      createVideoView({ variables: { videoId: video.id } });

      history.push('/rate_video');
    } catch (err) {
      console.log('ERROR', err);
    }
  }
}

const VideoControls = styled.div`
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  pointer-events: none;

  svg {
    fill: white;
    filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
  }

  ${tw`absolute flex items-center justify-center inset-0`}
`;

export default withOrientationGuard(WatchVideoPage);
