import { useMutation } from '@apollo/react-hooks';
import { useFormik } from 'formik';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Button from '../../components/Button';
import Layout from '../../layouts';
import { LoginMutation, LoginMutationVariables } from '../../types/graphql-generated';

type FormValues = {
  email: string;
  password: string;
};

function LoginPage({ redirectPath }: { redirectPath?: string }) {
  const history = useHistory();
  const [formError, setFormError] = useState('');

  const { handleSubmit, values, setFieldValue, isValid } = useFormik<FormValues>({
    initialValues: {
      email: '',
      password: '',
    },
    isInitialValid: false,
    onSubmit,
    validationSchema,
  });

  const [login] = useMutation<LoginMutation, LoginMutationVariables>(LOGIN);

  return (
    <Layout>
      <h3 className="text-white font-poppins mb-3 font-semibold text-3xl">Log In</h3>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email" className="block font-poppins font-medium leading-5 text-white">
            Email
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              value={values.email}
              onFocus={() => setFormError('')}
              onChange={event => setFieldValue('email', event.target.value)}
              onBlur={() => setFieldValue('email', values.email.toLowerCase().trim())}
              id="email"
              className="form-input font-poppins rounded-none block w-64"
              placeholder="you@example.com"
            />
          </div>
        </div>
        <div className="mt-4">
          <label htmlFor="password" className="block font-poppins font-medium leading-5 text-white">
            Password
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              type="password"
              onFocus={() => setFormError('')}
              value={values.password}
              onChange={event => setFieldValue('password', event.target.value)}
              id="password"
              className="form-input font-poppins rounded-none block w-64"
              placeholder="*********"
            />
          </div>
        </div>

        {formError && (
          <div className="mt-3 w-72">
            <p className="mt-2 font-semibold font-poppins text-red-500">{formError}</p>
          </div>
        )}

        <Button title="LOG IN" disabled={!isValid} type="submit" />
      </form>
    </Layout>
  );

  async function onSubmit(formValues: FormValues) {
    try {
      const mutationResult = await login({ variables: formValues });

      if (mutationResult.data) {
        const { accessToken } = mutationResult.data.login;

        localStorage.setItem('ACCESS_TOKEN', accessToken);

        if (redirectPath) {
          history.push(redirectPath);
        } else {
          history.push('/score');
        }
      }
    } catch ({ graphQLErrors }) {
      const { message } = graphQLErrors[0];

      if (message === 'NO_USER_FOUND_FOR_CREDENTIALS') {
        setFormError('No user was found for this email and password combination.');
      }
    }
  }
}

const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
    }
  }
`;

export default LoginPage;

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Please provide a valid email address.')
    .required('Please provide a valid email address.'),
  password: Yup.string()
    .required('Please provide a password.')
    .min(6, 'Your password must contain at least 6 characters.'),
});
