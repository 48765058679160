import { useState, useLayoutEffect, RefObject } from 'react';

function useVideoPlayer(videoRef: RefObject<HTMLVideoElement>) {
  const [isPlaying, setIsPlaying] = useState(false);

  useLayoutEffect(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [isPlaying, videoRef]);

  function togglePlay() {
    setIsPlaying(state => !state);
  }

  return { isPlaying, togglePlay };
}

export default useVideoPlayer;
