import gql from 'graphql-tag';

// Fragments
export const AnswerBaseFragment = gql`
  fragment AnswerBaseFragment on answers {
    id
    text
    is_correct
  }
`;
