import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ANSWERED_QUESTION_SETS } from '../../api/answeredQuestionSets';
import Button from '../../components/Button';
import { POINTS_TO_WIN_PRIZES, RATE_VIDEO_POINTS } from '../../config/constants';
import Layout from '../../layouts';
import { verifyAuthContext } from '../../libs/authentication';
import { AnsweredQuestionSetsQuery } from '../../types/graphql-generated';

function ScorePage() {
  const authContext = verifyAuthContext();

  const history = useHistory();
  const location = useLocation<{ videoRated: boolean }>();

  const { data, error } = useQuery<AnsweredQuestionSetsQuery>(ANSWERED_QUESTION_SETS, {
    fetchPolicy: 'network-only',
    variables: { userId: authContext?.userId },
  });

  if (!data) {
    return (
      <Layout>
        <div></div>
      </Layout>
    );
  }

  if (error) {
    return <div>Woops!</div>;
  }

  const { answered_questions } = data.answered_question_sets[0];

  const questionsAnswered = answered_questions.length;

  const questionsCorrect = answered_questions.filter(
    answeredQuestion => !!answeredQuestion.answer && answeredQuestion.answer.is_correct,
  );

  const questionsCorrectAmount = questionsCorrect.length;

  const totalScore = questionsCorrect.reduce(
    (accumulator, answeredQuestion) => {
      return accumulator + answeredQuestion.score;
    },
    location.state?.videoRated ? RATE_VIDEO_POINTS : 0,
  );

  const correctPercentage = (questionsCorrectAmount / questionsAnswered) * 100;

  const highscore = data.answered_question_set_high_score[0]!.high_score!;

  const percentile = (totalScore / highscore) * 100;

  const percentileRounded = Math.round((percentile + Number.EPSILON) * 100) / 100;

  return (
    <Layout>
      <h2 className="font-poppins text-gray-800 text-4xl">YOUR RESULTS ARE IN*</h2>

      <div className="flex flex-col items-center mt-2">
        <div className="font-poppins text-2xl text-gray-800">
          <p className="my-1">
            <b>GRADE</b>: <b>{questionsCorrectAmount}</b> of <b>{questionsAnswered}</b> ({correctPercentage}%)
          </p>
          <p className="my-1">
            <b>SCORE</b>: {totalScore} Points
          </p>
          <p className="my-1">
            <b>PERCENTILE</b>: {percentileRounded}%
          </p>
        </div>

        <p className="font-poppins text-sm text-gray-800 my-4">
          * Grades of 100% with {POINTS_TO_WIN_PRIZES}+ points eligible for prizes
        </p>

        <div className="flex items-center">
          <Button title="PLAY AGAIN" handleClick={handleNavigation} />
          <p className="text-gray-800 text-lg mt-4 ml-8">(max. twice/week)</p>
        </div>
      </div>
    </Layout>
  );

  function handleNavigation() {
    history.push('/');
  }
}

export default ScorePage;
