import gql from 'graphql-tag';

// Fragments
export const AnsweredQuestionBaseFragment = gql`
  fragment AnsweredQuestionBaseFragment on answered_questions {
    id
    score
    question {
      type
    }
    answer {
      is_correct
    }
  }
`;

// Queries

export const ANSWERED_QUESTION_SET_SCORE = gql`
  query answeredQuestionSetScore($startDatetime: timestamptz, $endDatetime: timestamptz) {
    answered_question_set_score(
      order_by: { high_score: desc }
      where: { created_at: { _gte: $startDatetime, _lte: $endDatetime } }
    ) {
      high_score
      first_name
      email
      zip_code
      created_at
    }
  }
`;

export const ANSWERED_QUESTION_SETS = gql`
  query answeredQuestionSets($userId: Int!) {
    answered_question_sets(order_by: { created_at: desc }, where: { user_id: { _eq: $userId } }, limit: 1) {
      id
      created_at
      answered_questions {
        ...AnsweredQuestionBaseFragment
      }
    }
    answered_question_set_high_score {
      high_score
    }
  }
  ${AnsweredQuestionBaseFragment}
`;
