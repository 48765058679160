export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  timestamptz: any;
  bigint: any;
  date: any;
  json: any;
  uuid: any;
};

export type Answered_Question_Set_High_Score = {
  __typename: 'answered_question_set_high_score';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  high_score?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Answered_Question_Set_High_Score_Aggregate = {
  __typename: 'answered_question_set_high_score_aggregate';
  aggregate?: Maybe<Answered_Question_Set_High_Score_Aggregate_Fields>;
  nodes: Array<Answered_Question_Set_High_Score>;
};

export type Answered_Question_Set_High_Score_Aggregate_Fields = {
  __typename: 'answered_question_set_high_score_aggregate_fields';
  avg?: Maybe<Answered_Question_Set_High_Score_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Answered_Question_Set_High_Score_Max_Fields>;
  min?: Maybe<Answered_Question_Set_High_Score_Min_Fields>;
  stddev?: Maybe<Answered_Question_Set_High_Score_Stddev_Fields>;
  stddev_pop?: Maybe<Answered_Question_Set_High_Score_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Answered_Question_Set_High_Score_Stddev_Samp_Fields>;
  sum?: Maybe<Answered_Question_Set_High_Score_Sum_Fields>;
  var_pop?: Maybe<Answered_Question_Set_High_Score_Var_Pop_Fields>;
  var_samp?: Maybe<Answered_Question_Set_High_Score_Var_Samp_Fields>;
  variance?: Maybe<Answered_Question_Set_High_Score_Variance_Fields>;
};

export type Answered_Question_Set_High_Score_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Answered_Question_Set_High_Score_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type Answered_Question_Set_High_Score_Aggregate_Order_By = {
  avg?: Maybe<Answered_Question_Set_High_Score_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Answered_Question_Set_High_Score_Max_Order_By>;
  min?: Maybe<Answered_Question_Set_High_Score_Min_Order_By>;
  stddev?: Maybe<Answered_Question_Set_High_Score_Stddev_Order_By>;
  stddev_pop?: Maybe<Answered_Question_Set_High_Score_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Answered_Question_Set_High_Score_Stddev_Samp_Order_By>;
  sum?: Maybe<Answered_Question_Set_High_Score_Sum_Order_By>;
  var_pop?: Maybe<Answered_Question_Set_High_Score_Var_Pop_Order_By>;
  var_samp?: Maybe<Answered_Question_Set_High_Score_Var_Samp_Order_By>;
  variance?: Maybe<Answered_Question_Set_High_Score_Variance_Order_By>;
};

export type Answered_Question_Set_High_Score_Avg_Fields = {
  __typename: 'answered_question_set_high_score_avg_fields';
  high_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Question_Set_High_Score_Avg_Order_By = {
  high_score?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Set_High_Score_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Answered_Question_Set_High_Score_Bool_Exp>>>;
  _not?: Maybe<Answered_Question_Set_High_Score_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Answered_Question_Set_High_Score_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  high_score?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  video_id?: Maybe<Int_Comparison_Exp>;
};

export type Answered_Question_Set_High_Score_Max_Fields = {
  __typename: 'answered_question_set_high_score_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  high_score?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Answered_Question_Set_High_Score_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  high_score?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Set_High_Score_Min_Fields = {
  __typename: 'answered_question_set_high_score_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  high_score?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Answered_Question_Set_High_Score_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  high_score?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Set_High_Score_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  high_score?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export enum Answered_Question_Set_High_Score_Select_Column {
  CREATED_AT = 'created_at',
  EMAIL = 'email',
  FIRST_NAME = 'first_name',
  HIGH_SCORE = 'high_score',
  ID = 'id',
  VIDEO_ID = 'video_id',
}

export type Answered_Question_Set_High_Score_Stddev_Fields = {
  __typename: 'answered_question_set_high_score_stddev_fields';
  high_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Question_Set_High_Score_Stddev_Order_By = {
  high_score?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Set_High_Score_Stddev_Pop_Fields = {
  __typename: 'answered_question_set_high_score_stddev_pop_fields';
  high_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Question_Set_High_Score_Stddev_Pop_Order_By = {
  high_score?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Set_High_Score_Stddev_Samp_Fields = {
  __typename: 'answered_question_set_high_score_stddev_samp_fields';
  high_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Question_Set_High_Score_Stddev_Samp_Order_By = {
  high_score?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Set_High_Score_Sum_Fields = {
  __typename: 'answered_question_set_high_score_sum_fields';
  high_score?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Answered_Question_Set_High_Score_Sum_Order_By = {
  high_score?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Set_High_Score_Var_Pop_Fields = {
  __typename: 'answered_question_set_high_score_var_pop_fields';
  high_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Question_Set_High_Score_Var_Pop_Order_By = {
  high_score?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Set_High_Score_Var_Samp_Fields = {
  __typename: 'answered_question_set_high_score_var_samp_fields';
  high_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Question_Set_High_Score_Var_Samp_Order_By = {
  high_score?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Set_High_Score_Variance_Fields = {
  __typename: 'answered_question_set_high_score_variance_fields';
  high_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Question_Set_High_Score_Variance_Order_By = {
  high_score?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Set_Score = {
  __typename: 'answered_question_set_score';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  high_score?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
  zip_code?: Maybe<Scalars['String']>;
};

export type Answered_Question_Set_Score_Aggregate = {
  __typename: 'answered_question_set_score_aggregate';
  aggregate?: Maybe<Answered_Question_Set_Score_Aggregate_Fields>;
  nodes: Array<Answered_Question_Set_Score>;
};

export type Answered_Question_Set_Score_Aggregate_Fields = {
  __typename: 'answered_question_set_score_aggregate_fields';
  avg?: Maybe<Answered_Question_Set_Score_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Answered_Question_Set_Score_Max_Fields>;
  min?: Maybe<Answered_Question_Set_Score_Min_Fields>;
  stddev?: Maybe<Answered_Question_Set_Score_Stddev_Fields>;
  stddev_pop?: Maybe<Answered_Question_Set_Score_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Answered_Question_Set_Score_Stddev_Samp_Fields>;
  sum?: Maybe<Answered_Question_Set_Score_Sum_Fields>;
  var_pop?: Maybe<Answered_Question_Set_Score_Var_Pop_Fields>;
  var_samp?: Maybe<Answered_Question_Set_Score_Var_Samp_Fields>;
  variance?: Maybe<Answered_Question_Set_Score_Variance_Fields>;
};

export type Answered_Question_Set_Score_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Answered_Question_Set_Score_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type Answered_Question_Set_Score_Aggregate_Order_By = {
  avg?: Maybe<Answered_Question_Set_Score_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Answered_Question_Set_Score_Max_Order_By>;
  min?: Maybe<Answered_Question_Set_Score_Min_Order_By>;
  stddev?: Maybe<Answered_Question_Set_Score_Stddev_Order_By>;
  stddev_pop?: Maybe<Answered_Question_Set_Score_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Answered_Question_Set_Score_Stddev_Samp_Order_By>;
  sum?: Maybe<Answered_Question_Set_Score_Sum_Order_By>;
  var_pop?: Maybe<Answered_Question_Set_Score_Var_Pop_Order_By>;
  var_samp?: Maybe<Answered_Question_Set_Score_Var_Samp_Order_By>;
  variance?: Maybe<Answered_Question_Set_Score_Variance_Order_By>;
};

export type Answered_Question_Set_Score_Avg_Fields = {
  __typename: 'answered_question_set_score_avg_fields';
  high_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Question_Set_Score_Avg_Order_By = {
  high_score?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Set_Score_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Answered_Question_Set_Score_Bool_Exp>>>;
  _not?: Maybe<Answered_Question_Set_Score_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Answered_Question_Set_Score_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  high_score?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  video_id?: Maybe<Int_Comparison_Exp>;
  zip_code?: Maybe<String_Comparison_Exp>;
};

export type Answered_Question_Set_Score_Max_Fields = {
  __typename: 'answered_question_set_score_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  high_score?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
  zip_code?: Maybe<Scalars['String']>;
};

export type Answered_Question_Set_Score_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  high_score?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

export type Answered_Question_Set_Score_Min_Fields = {
  __typename: 'answered_question_set_score_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  high_score?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
  zip_code?: Maybe<Scalars['String']>;
};

export type Answered_Question_Set_Score_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  high_score?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

export type Answered_Question_Set_Score_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  high_score?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

export enum Answered_Question_Set_Score_Select_Column {
  CREATED_AT = 'created_at',
  EMAIL = 'email',
  FIRST_NAME = 'first_name',
  HIGH_SCORE = 'high_score',
  ID = 'id',
  VIDEO_ID = 'video_id',
  ZIP_CODE = 'zip_code',
}

export type Answered_Question_Set_Score_Stddev_Fields = {
  __typename: 'answered_question_set_score_stddev_fields';
  high_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Question_Set_Score_Stddev_Order_By = {
  high_score?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Set_Score_Stddev_Pop_Fields = {
  __typename: 'answered_question_set_score_stddev_pop_fields';
  high_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Question_Set_Score_Stddev_Pop_Order_By = {
  high_score?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Set_Score_Stddev_Samp_Fields = {
  __typename: 'answered_question_set_score_stddev_samp_fields';
  high_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Question_Set_Score_Stddev_Samp_Order_By = {
  high_score?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Set_Score_Sum_Fields = {
  __typename: 'answered_question_set_score_sum_fields';
  high_score?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Answered_Question_Set_Score_Sum_Order_By = {
  high_score?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Set_Score_Var_Pop_Fields = {
  __typename: 'answered_question_set_score_var_pop_fields';
  high_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Question_Set_Score_Var_Pop_Order_By = {
  high_score?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Set_Score_Var_Samp_Fields = {
  __typename: 'answered_question_set_score_var_samp_fields';
  high_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Question_Set_Score_Var_Samp_Order_By = {
  high_score?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Set_Score_Variance_Fields = {
  __typename: 'answered_question_set_score_variance_fields';
  high_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Question_Set_Score_Variance_Order_By = {
  high_score?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Sets = {
  __typename: 'answered_question_sets';
  answered_questions: Array<Answered_Questions>;
  answered_questions_aggregate: Answered_Questions_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  user: Users;
  user_id: Scalars['Int'];
  video: Videos;
  video_id: Scalars['Int'];
};

export type Answered_Question_SetsAnswered_QuestionsArgs = {
  distinct_on?: Maybe<Array<Answered_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Questions_Order_By>>;
  where?: Maybe<Answered_Questions_Bool_Exp>;
};

export type Answered_Question_SetsAnswered_Questions_AggregateArgs = {
  distinct_on?: Maybe<Array<Answered_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Questions_Order_By>>;
  where?: Maybe<Answered_Questions_Bool_Exp>;
};

export type Answered_Question_Sets_Aggregate = {
  __typename: 'answered_question_sets_aggregate';
  aggregate?: Maybe<Answered_Question_Sets_Aggregate_Fields>;
  nodes: Array<Answered_Question_Sets>;
};

export type Answered_Question_Sets_Aggregate_Fields = {
  __typename: 'answered_question_sets_aggregate_fields';
  avg?: Maybe<Answered_Question_Sets_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Answered_Question_Sets_Max_Fields>;
  min?: Maybe<Answered_Question_Sets_Min_Fields>;
  stddev?: Maybe<Answered_Question_Sets_Stddev_Fields>;
  stddev_pop?: Maybe<Answered_Question_Sets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Answered_Question_Sets_Stddev_Samp_Fields>;
  sum?: Maybe<Answered_Question_Sets_Sum_Fields>;
  var_pop?: Maybe<Answered_Question_Sets_Var_Pop_Fields>;
  var_samp?: Maybe<Answered_Question_Sets_Var_Samp_Fields>;
  variance?: Maybe<Answered_Question_Sets_Variance_Fields>;
};

export type Answered_Question_Sets_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Answered_Question_Sets_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type Answered_Question_Sets_Aggregate_Order_By = {
  avg?: Maybe<Answered_Question_Sets_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Answered_Question_Sets_Max_Order_By>;
  min?: Maybe<Answered_Question_Sets_Min_Order_By>;
  stddev?: Maybe<Answered_Question_Sets_Stddev_Order_By>;
  stddev_pop?: Maybe<Answered_Question_Sets_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Answered_Question_Sets_Stddev_Samp_Order_By>;
  sum?: Maybe<Answered_Question_Sets_Sum_Order_By>;
  var_pop?: Maybe<Answered_Question_Sets_Var_Pop_Order_By>;
  var_samp?: Maybe<Answered_Question_Sets_Var_Samp_Order_By>;
  variance?: Maybe<Answered_Question_Sets_Variance_Order_By>;
};

export type Answered_Question_Sets_Arr_Rel_Insert_Input = {
  data: Array<Answered_Question_Sets_Insert_Input>;
  on_conflict?: Maybe<Answered_Question_Sets_On_Conflict>;
};

export type Answered_Question_Sets_Avg_Fields = {
  __typename: 'answered_question_sets_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Question_Sets_Avg_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Sets_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Answered_Question_Sets_Bool_Exp>>>;
  _not?: Maybe<Answered_Question_Sets_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Answered_Question_Sets_Bool_Exp>>>;
  answered_questions?: Maybe<Answered_Questions_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
  video?: Maybe<Videos_Bool_Exp>;
  video_id?: Maybe<Int_Comparison_Exp>;
};

export enum Answered_Question_Sets_Constraint {
  ANSWERED_QUESTIONS_SET_PKEY = 'answered_questions_set_pkey',
}

export type Answered_Question_Sets_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Answered_Question_Sets_Insert_Input = {
  answered_questions?: Maybe<Answered_Questions_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['Int']>;
  video?: Maybe<Videos_Obj_Rel_Insert_Input>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Answered_Question_Sets_Max_Fields = {
  __typename: 'answered_question_sets_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Answered_Question_Sets_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Sets_Min_Fields = {
  __typename: 'answered_question_sets_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Answered_Question_Sets_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Sets_Mutation_Response = {
  __typename: 'answered_question_sets_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Answered_Question_Sets>;
};

export type Answered_Question_Sets_Obj_Rel_Insert_Input = {
  data: Answered_Question_Sets_Insert_Input;
  on_conflict?: Maybe<Answered_Question_Sets_On_Conflict>;
};

export type Answered_Question_Sets_On_Conflict = {
  constraint: Answered_Question_Sets_Constraint;
  update_columns: Array<Answered_Question_Sets_Update_Column>;
  where?: Maybe<Answered_Question_Sets_Bool_Exp>;
};

export type Answered_Question_Sets_Order_By = {
  answered_questions_aggregate?: Maybe<Answered_Questions_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
  video?: Maybe<Videos_Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Sets_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Answered_Question_Sets_Select_Column {
  CREATED_AT = 'created_at',
  ID = 'id',
  USER_ID = 'user_id',
  VIDEO_ID = 'video_id',
}

export type Answered_Question_Sets_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Answered_Question_Sets_Stddev_Fields = {
  __typename: 'answered_question_sets_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Question_Sets_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Sets_Stddev_Pop_Fields = {
  __typename: 'answered_question_sets_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Question_Sets_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Sets_Stddev_Samp_Fields = {
  __typename: 'answered_question_sets_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Question_Sets_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Sets_Sum_Fields = {
  __typename: 'answered_question_sets_sum_fields';
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Answered_Question_Sets_Sum_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export enum Answered_Question_Sets_Update_Column {
  CREATED_AT = 'created_at',
  ID = 'id',
  USER_ID = 'user_id',
  VIDEO_ID = 'video_id',
}

export type Answered_Question_Sets_Var_Pop_Fields = {
  __typename: 'answered_question_sets_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Question_Sets_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Sets_Var_Samp_Fields = {
  __typename: 'answered_question_sets_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Question_Sets_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Question_Sets_Variance_Fields = {
  __typename: 'answered_question_sets_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Question_Sets_Variance_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Questions = {
  __typename: 'answered_questions';
  answer?: Maybe<Answers>;
  answer_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  question: Questions;
  question_id: Scalars['Int'];
  score: Scalars['Int'];
  set: Answered_Question_Sets;
  set_id: Scalars['Int'];
  user: Users;
  user_id: Scalars['Int'];
  video: Videos;
  video_id: Scalars['Int'];
};

export type Answered_Questions_Aggregate = {
  __typename: 'answered_questions_aggregate';
  aggregate?: Maybe<Answered_Questions_Aggregate_Fields>;
  nodes: Array<Answered_Questions>;
};

export type Answered_Questions_Aggregate_Fields = {
  __typename: 'answered_questions_aggregate_fields';
  avg?: Maybe<Answered_Questions_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Answered_Questions_Max_Fields>;
  min?: Maybe<Answered_Questions_Min_Fields>;
  stddev?: Maybe<Answered_Questions_Stddev_Fields>;
  stddev_pop?: Maybe<Answered_Questions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Answered_Questions_Stddev_Samp_Fields>;
  sum?: Maybe<Answered_Questions_Sum_Fields>;
  var_pop?: Maybe<Answered_Questions_Var_Pop_Fields>;
  var_samp?: Maybe<Answered_Questions_Var_Samp_Fields>;
  variance?: Maybe<Answered_Questions_Variance_Fields>;
};

export type Answered_Questions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Answered_Questions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type Answered_Questions_Aggregate_Order_By = {
  avg?: Maybe<Answered_Questions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Answered_Questions_Max_Order_By>;
  min?: Maybe<Answered_Questions_Min_Order_By>;
  stddev?: Maybe<Answered_Questions_Stddev_Order_By>;
  stddev_pop?: Maybe<Answered_Questions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Answered_Questions_Stddev_Samp_Order_By>;
  sum?: Maybe<Answered_Questions_Sum_Order_By>;
  var_pop?: Maybe<Answered_Questions_Var_Pop_Order_By>;
  var_samp?: Maybe<Answered_Questions_Var_Samp_Order_By>;
  variance?: Maybe<Answered_Questions_Variance_Order_By>;
};

export type Answered_Questions_Arr_Rel_Insert_Input = {
  data: Array<Answered_Questions_Insert_Input>;
  on_conflict?: Maybe<Answered_Questions_On_Conflict>;
};

export type Answered_Questions_Avg_Fields = {
  __typename: 'answered_questions_avg_fields';
  answer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  set_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Questions_Avg_Order_By = {
  answer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  set_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Questions_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Answered_Questions_Bool_Exp>>>;
  _not?: Maybe<Answered_Questions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Answered_Questions_Bool_Exp>>>;
  answer?: Maybe<Answers_Bool_Exp>;
  answer_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  question?: Maybe<Questions_Bool_Exp>;
  question_id?: Maybe<Int_Comparison_Exp>;
  score?: Maybe<Int_Comparison_Exp>;
  set?: Maybe<Answered_Question_Sets_Bool_Exp>;
  set_id?: Maybe<Int_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
  video?: Maybe<Videos_Bool_Exp>;
  video_id?: Maybe<Int_Comparison_Exp>;
};

export enum Answered_Questions_Constraint {
  ANSWERED_QUESTIONS_PKEY = 'answered_questions_pkey',
  ANSWERED_QUESTIONS_USER_ID_QUESTION_ID_SET_ID_KEY = 'answered_questions_user_id_question_id_set_id_key',
}

export type Answered_Questions_Inc_Input = {
  answer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  set_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Answered_Questions_Insert_Input = {
  answer?: Maybe<Answers_Obj_Rel_Insert_Input>;
  answer_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  question?: Maybe<Questions_Obj_Rel_Insert_Input>;
  question_id?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  set?: Maybe<Answered_Question_Sets_Obj_Rel_Insert_Input>;
  set_id?: Maybe<Scalars['Int']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['Int']>;
  video?: Maybe<Videos_Obj_Rel_Insert_Input>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Answered_Questions_Max_Fields = {
  __typename: 'answered_questions_max_fields';
  answer_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  set_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Answered_Questions_Max_Order_By = {
  answer_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  set_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Questions_Min_Fields = {
  __typename: 'answered_questions_min_fields';
  answer_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  set_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Answered_Questions_Min_Order_By = {
  answer_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  set_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Questions_Mutation_Response = {
  __typename: 'answered_questions_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Answered_Questions>;
};

export type Answered_Questions_Obj_Rel_Insert_Input = {
  data: Answered_Questions_Insert_Input;
  on_conflict?: Maybe<Answered_Questions_On_Conflict>;
};

export type Answered_Questions_On_Conflict = {
  constraint: Answered_Questions_Constraint;
  update_columns: Array<Answered_Questions_Update_Column>;
  where?: Maybe<Answered_Questions_Bool_Exp>;
};

export type Answered_Questions_Order_By = {
  answer?: Maybe<Answers_Order_By>;
  answer_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question?: Maybe<Questions_Order_By>;
  question_id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  set?: Maybe<Answered_Question_Sets_Order_By>;
  set_id?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
  video?: Maybe<Videos_Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Questions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Answered_Questions_Select_Column {
  ANSWER_ID = 'answer_id',
  CREATED_AT = 'created_at',
  ID = 'id',
  QUESTION_ID = 'question_id',
  SCORE = 'score',
  SET_ID = 'set_id',
  USER_ID = 'user_id',
  VIDEO_ID = 'video_id',
}

export type Answered_Questions_Set_Input = {
  answer_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  set_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Answered_Questions_Stddev_Fields = {
  __typename: 'answered_questions_stddev_fields';
  answer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  set_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Questions_Stddev_Order_By = {
  answer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  set_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Questions_Stddev_Pop_Fields = {
  __typename: 'answered_questions_stddev_pop_fields';
  answer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  set_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Questions_Stddev_Pop_Order_By = {
  answer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  set_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Questions_Stddev_Samp_Fields = {
  __typename: 'answered_questions_stddev_samp_fields';
  answer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  set_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Questions_Stddev_Samp_Order_By = {
  answer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  set_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Questions_Sum_Fields = {
  __typename: 'answered_questions_sum_fields';
  answer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  set_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Answered_Questions_Sum_Order_By = {
  answer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  set_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export enum Answered_Questions_Update_Column {
  ANSWER_ID = 'answer_id',
  CREATED_AT = 'created_at',
  ID = 'id',
  QUESTION_ID = 'question_id',
  SCORE = 'score',
  SET_ID = 'set_id',
  USER_ID = 'user_id',
  VIDEO_ID = 'video_id',
}

export type Answered_Questions_Var_Pop_Fields = {
  __typename: 'answered_questions_var_pop_fields';
  answer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  set_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Questions_Var_Pop_Order_By = {
  answer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  set_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Questions_Var_Samp_Fields = {
  __typename: 'answered_questions_var_samp_fields';
  answer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  set_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Questions_Var_Samp_Order_By = {
  answer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  set_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answered_Questions_Variance_Fields = {
  __typename: 'answered_questions_variance_fields';
  answer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  set_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Answered_Questions_Variance_Order_By = {
  answer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  set_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Answers = {
  __typename: 'answers';
  answered_questions: Array<Answered_Questions>;
  answered_questions_aggregate: Answered_Questions_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  is_correct: Scalars['Boolean'];
  question: Questions;
  question_id: Scalars['Int'];
  text: Scalars['String'];
};

export type AnswersAnswered_QuestionsArgs = {
  distinct_on?: Maybe<Array<Answered_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Questions_Order_By>>;
  where?: Maybe<Answered_Questions_Bool_Exp>;
};

export type AnswersAnswered_Questions_AggregateArgs = {
  distinct_on?: Maybe<Array<Answered_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Questions_Order_By>>;
  where?: Maybe<Answered_Questions_Bool_Exp>;
};

export type Answers_Aggregate = {
  __typename: 'answers_aggregate';
  aggregate?: Maybe<Answers_Aggregate_Fields>;
  nodes: Array<Answers>;
};

export type Answers_Aggregate_Fields = {
  __typename: 'answers_aggregate_fields';
  avg?: Maybe<Answers_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Answers_Max_Fields>;
  min?: Maybe<Answers_Min_Fields>;
  stddev?: Maybe<Answers_Stddev_Fields>;
  stddev_pop?: Maybe<Answers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Answers_Stddev_Samp_Fields>;
  sum?: Maybe<Answers_Sum_Fields>;
  var_pop?: Maybe<Answers_Var_Pop_Fields>;
  var_samp?: Maybe<Answers_Var_Samp_Fields>;
  variance?: Maybe<Answers_Variance_Fields>;
};

export type Answers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Answers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type Answers_Aggregate_Order_By = {
  avg?: Maybe<Answers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Answers_Max_Order_By>;
  min?: Maybe<Answers_Min_Order_By>;
  stddev?: Maybe<Answers_Stddev_Order_By>;
  stddev_pop?: Maybe<Answers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Answers_Stddev_Samp_Order_By>;
  sum?: Maybe<Answers_Sum_Order_By>;
  var_pop?: Maybe<Answers_Var_Pop_Order_By>;
  var_samp?: Maybe<Answers_Var_Samp_Order_By>;
  variance?: Maybe<Answers_Variance_Order_By>;
};

export type Answers_Arr_Rel_Insert_Input = {
  data: Array<Answers_Insert_Input>;
  on_conflict?: Maybe<Answers_On_Conflict>;
};

export type Answers_Avg_Fields = {
  __typename: 'answers_avg_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

export type Answers_Avg_Order_By = {
  id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
};

export type Answers_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Answers_Bool_Exp>>>;
  _not?: Maybe<Answers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Answers_Bool_Exp>>>;
  answered_questions?: Maybe<Answered_Questions_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_correct?: Maybe<Boolean_Comparison_Exp>;
  question?: Maybe<Questions_Bool_Exp>;
  question_id?: Maybe<Int_Comparison_Exp>;
  text?: Maybe<String_Comparison_Exp>;
};

export enum Answers_Constraint {
  ANSWERS_PKEY = 'answers_pkey',
}

export type Answers_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Int']>;
};

export type Answers_Insert_Input = {
  answered_questions?: Maybe<Answered_Questions_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  is_correct?: Maybe<Scalars['Boolean']>;
  question?: Maybe<Questions_Obj_Rel_Insert_Input>;
  question_id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
};

export type Answers_Max_Fields = {
  __typename: 'answers_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
};

export type Answers_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
};

export type Answers_Min_Fields = {
  __typename: 'answers_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
};

export type Answers_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
};

export type Answers_Mutation_Response = {
  __typename: 'answers_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Answers>;
};

export type Answers_Obj_Rel_Insert_Input = {
  data: Answers_Insert_Input;
  on_conflict?: Maybe<Answers_On_Conflict>;
};

export type Answers_On_Conflict = {
  constraint: Answers_Constraint;
  update_columns: Array<Answers_Update_Column>;
  where?: Maybe<Answers_Bool_Exp>;
};

export type Answers_Order_By = {
  answered_questions_aggregate?: Maybe<Answered_Questions_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_correct?: Maybe<Order_By>;
  question?: Maybe<Questions_Order_By>;
  question_id?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
};

export type Answers_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Answers_Select_Column {
  CREATED_AT = 'created_at',
  ID = 'id',
  IS_CORRECT = 'is_correct',
  QUESTION_ID = 'question_id',
  TEXT = 'text',
}

export type Answers_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  is_correct?: Maybe<Scalars['Boolean']>;
  question_id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
};

export type Answers_Stddev_Fields = {
  __typename: 'answers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

export type Answers_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
};

export type Answers_Stddev_Pop_Fields = {
  __typename: 'answers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

export type Answers_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
};

export type Answers_Stddev_Samp_Fields = {
  __typename: 'answers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

export type Answers_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
};

export type Answers_Sum_Fields = {
  __typename: 'answers_sum_fields';
  id?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Int']>;
};

export type Answers_Sum_Order_By = {
  id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
};

export enum Answers_Update_Column {
  CREATED_AT = 'created_at',
  ID = 'id',
  IS_CORRECT = 'is_correct',
  QUESTION_ID = 'question_id',
  TEXT = 'text',
}

export type Answers_Var_Pop_Fields = {
  __typename: 'answers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

export type Answers_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
};

export type Answers_Var_Samp_Fields = {
  __typename: 'answers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

export type Answers_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
};

export type Answers_Variance_Fields = {
  __typename: 'answers_variance_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

export type Answers_Variance_Order_By = {
  id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
};

export type AuthOutput = {
  __typename: 'AuthOutput';
  accessToken: Scalars['String'];
};

export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
};

export type Mutation_Root = {
  __typename: 'mutation_root';
  createUnknownUser: AuthOutput;
  delete_answered_question_sets?: Maybe<Answered_Question_Sets_Mutation_Response>;
  delete_answered_question_sets_by_pk?: Maybe<Answered_Question_Sets>;
  delete_answered_questions?: Maybe<Answered_Questions_Mutation_Response>;
  delete_answered_questions_by_pk?: Maybe<Answered_Questions>;
  delete_answers?: Maybe<Answers_Mutation_Response>;
  delete_answers_by_pk?: Maybe<Answers>;
  delete_question_types?: Maybe<Question_Types_Mutation_Response>;
  delete_question_types_by_pk?: Maybe<Question_Types>;
  delete_questions?: Maybe<Questions_Mutation_Response>;
  delete_questions_by_pk?: Maybe<Questions>;
  delete_user_gender?: Maybe<User_Gender_Mutation_Response>;
  delete_user_gender_by_pk?: Maybe<User_Gender>;
  delete_user_roles?: Maybe<User_Roles_Mutation_Response>;
  delete_user_roles_by_pk?: Maybe<User_Roles>;
  delete_users?: Maybe<Users_Mutation_Response>;
  delete_users_by_pk?: Maybe<Users>;
  delete_video_ratings?: Maybe<Video_Ratings_Mutation_Response>;
  delete_video_ratings_by_pk?: Maybe<Video_Ratings>;
  delete_video_views?: Maybe<Video_Views_Mutation_Response>;
  delete_video_views_by_pk?: Maybe<Video_Views>;
  delete_videos?: Maybe<Videos_Mutation_Response>;
  delete_videos_by_pk?: Maybe<Videos>;
  insert_answered_question_sets?: Maybe<Answered_Question_Sets_Mutation_Response>;
  insert_answered_question_sets_one?: Maybe<Answered_Question_Sets>;
  insert_answered_questions?: Maybe<Answered_Questions_Mutation_Response>;
  insert_answered_questions_one?: Maybe<Answered_Questions>;
  insert_answers?: Maybe<Answers_Mutation_Response>;
  insert_answers_one?: Maybe<Answers>;
  insert_question_types?: Maybe<Question_Types_Mutation_Response>;
  insert_question_types_one?: Maybe<Question_Types>;
  insert_questions?: Maybe<Questions_Mutation_Response>;
  insert_questions_one?: Maybe<Questions>;
  insert_user_gender?: Maybe<User_Gender_Mutation_Response>;
  insert_user_gender_one?: Maybe<User_Gender>;
  insert_user_roles?: Maybe<User_Roles_Mutation_Response>;
  insert_user_roles_one?: Maybe<User_Roles>;
  insert_users?: Maybe<Users_Mutation_Response>;
  insert_users_one?: Maybe<Users>;
  insert_video_ratings?: Maybe<Video_Ratings_Mutation_Response>;
  insert_video_ratings_one?: Maybe<Video_Ratings>;
  insert_video_views?: Maybe<Video_Views_Mutation_Response>;
  insert_video_views_one?: Maybe<Video_Views>;
  insert_videos?: Maybe<Videos_Mutation_Response>;
  insert_videos_one?: Maybe<Videos>;
  login: AuthOutput;
  register: AuthOutput;
  update_answered_question_sets?: Maybe<Answered_Question_Sets_Mutation_Response>;
  update_answered_question_sets_by_pk?: Maybe<Answered_Question_Sets>;
  update_answered_questions?: Maybe<Answered_Questions_Mutation_Response>;
  update_answered_questions_by_pk?: Maybe<Answered_Questions>;
  update_answers?: Maybe<Answers_Mutation_Response>;
  update_answers_by_pk?: Maybe<Answers>;
  update_question_types?: Maybe<Question_Types_Mutation_Response>;
  update_question_types_by_pk?: Maybe<Question_Types>;
  update_questions?: Maybe<Questions_Mutation_Response>;
  update_questions_by_pk?: Maybe<Questions>;
  update_user_gender?: Maybe<User_Gender_Mutation_Response>;
  update_user_gender_by_pk?: Maybe<User_Gender>;
  update_user_roles?: Maybe<User_Roles_Mutation_Response>;
  update_user_roles_by_pk?: Maybe<User_Roles>;
  update_users?: Maybe<Users_Mutation_Response>;
  update_users_by_pk?: Maybe<Users>;
  update_video_ratings?: Maybe<Video_Ratings_Mutation_Response>;
  update_video_ratings_by_pk?: Maybe<Video_Ratings>;
  update_video_views?: Maybe<Video_Views_Mutation_Response>;
  update_video_views_by_pk?: Maybe<Video_Views>;
  update_videos?: Maybe<Videos_Mutation_Response>;
  update_videos_by_pk?: Maybe<Videos>;
};

export type Mutation_RootDelete_Answered_Question_SetsArgs = {
  where: Answered_Question_Sets_Bool_Exp;
};

export type Mutation_RootDelete_Answered_Question_Sets_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Answered_QuestionsArgs = {
  where: Answered_Questions_Bool_Exp;
};

export type Mutation_RootDelete_Answered_Questions_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_AnswersArgs = {
  where: Answers_Bool_Exp;
};

export type Mutation_RootDelete_Answers_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Question_TypesArgs = {
  where: Question_Types_Bool_Exp;
};

export type Mutation_RootDelete_Question_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_QuestionsArgs = {
  where: Questions_Bool_Exp;
};

export type Mutation_RootDelete_Questions_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_User_GenderArgs = {
  where: User_Gender_Bool_Exp;
};

export type Mutation_RootDelete_User_Gender_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_User_RolesArgs = {
  where: User_Roles_Bool_Exp;
};

export type Mutation_RootDelete_User_Roles_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};

export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Video_RatingsArgs = {
  where: Video_Ratings_Bool_Exp;
};

export type Mutation_RootDelete_Video_Ratings_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Video_ViewsArgs = {
  where: Video_Views_Bool_Exp;
};

export type Mutation_RootDelete_Video_Views_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_VideosArgs = {
  where: Videos_Bool_Exp;
};

export type Mutation_RootDelete_Videos_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootInsert_Answered_Question_SetsArgs = {
  objects: Array<Answered_Question_Sets_Insert_Input>;
  on_conflict?: Maybe<Answered_Question_Sets_On_Conflict>;
};

export type Mutation_RootInsert_Answered_Question_Sets_OneArgs = {
  object: Answered_Question_Sets_Insert_Input;
  on_conflict?: Maybe<Answered_Question_Sets_On_Conflict>;
};

export type Mutation_RootInsert_Answered_QuestionsArgs = {
  objects: Array<Answered_Questions_Insert_Input>;
  on_conflict?: Maybe<Answered_Questions_On_Conflict>;
};

export type Mutation_RootInsert_Answered_Questions_OneArgs = {
  object: Answered_Questions_Insert_Input;
  on_conflict?: Maybe<Answered_Questions_On_Conflict>;
};

export type Mutation_RootInsert_AnswersArgs = {
  objects: Array<Answers_Insert_Input>;
  on_conflict?: Maybe<Answers_On_Conflict>;
};

export type Mutation_RootInsert_Answers_OneArgs = {
  object: Answers_Insert_Input;
  on_conflict?: Maybe<Answers_On_Conflict>;
};

export type Mutation_RootInsert_Question_TypesArgs = {
  objects: Array<Question_Types_Insert_Input>;
  on_conflict?: Maybe<Question_Types_On_Conflict>;
};

export type Mutation_RootInsert_Question_Types_OneArgs = {
  object: Question_Types_Insert_Input;
  on_conflict?: Maybe<Question_Types_On_Conflict>;
};

export type Mutation_RootInsert_QuestionsArgs = {
  objects: Array<Questions_Insert_Input>;
  on_conflict?: Maybe<Questions_On_Conflict>;
};

export type Mutation_RootInsert_Questions_OneArgs = {
  object: Questions_Insert_Input;
  on_conflict?: Maybe<Questions_On_Conflict>;
};

export type Mutation_RootInsert_User_GenderArgs = {
  objects: Array<User_Gender_Insert_Input>;
  on_conflict?: Maybe<User_Gender_On_Conflict>;
};

export type Mutation_RootInsert_User_Gender_OneArgs = {
  object: User_Gender_Insert_Input;
  on_conflict?: Maybe<User_Gender_On_Conflict>;
};

export type Mutation_RootInsert_User_RolesArgs = {
  objects: Array<User_Roles_Insert_Input>;
  on_conflict?: Maybe<User_Roles_On_Conflict>;
};

export type Mutation_RootInsert_User_Roles_OneArgs = {
  object: User_Roles_Insert_Input;
  on_conflict?: Maybe<User_Roles_On_Conflict>;
};

export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};

export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};

export type Mutation_RootInsert_Video_RatingsArgs = {
  objects: Array<Video_Ratings_Insert_Input>;
  on_conflict?: Maybe<Video_Ratings_On_Conflict>;
};

export type Mutation_RootInsert_Video_Ratings_OneArgs = {
  object: Video_Ratings_Insert_Input;
  on_conflict?: Maybe<Video_Ratings_On_Conflict>;
};

export type Mutation_RootInsert_Video_ViewsArgs = {
  objects: Array<Video_Views_Insert_Input>;
  on_conflict?: Maybe<Video_Views_On_Conflict>;
};

export type Mutation_RootInsert_Video_Views_OneArgs = {
  object: Video_Views_Insert_Input;
  on_conflict?: Maybe<Video_Views_On_Conflict>;
};

export type Mutation_RootInsert_VideosArgs = {
  objects: Array<Videos_Insert_Input>;
  on_conflict?: Maybe<Videos_On_Conflict>;
};

export type Mutation_RootInsert_Videos_OneArgs = {
  object: Videos_Insert_Input;
  on_conflict?: Maybe<Videos_On_Conflict>;
};

export type Mutation_RootLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation_RootRegisterArgs = {
  birthDate?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: Maybe<Register_User_Gender_Enum>;
  password?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type Mutation_RootUpdate_Answered_Question_SetsArgs = {
  _inc?: Maybe<Answered_Question_Sets_Inc_Input>;
  _set?: Maybe<Answered_Question_Sets_Set_Input>;
  where: Answered_Question_Sets_Bool_Exp;
};

export type Mutation_RootUpdate_Answered_Question_Sets_By_PkArgs = {
  _inc?: Maybe<Answered_Question_Sets_Inc_Input>;
  _set?: Maybe<Answered_Question_Sets_Set_Input>;
  pk_columns: Answered_Question_Sets_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Answered_QuestionsArgs = {
  _inc?: Maybe<Answered_Questions_Inc_Input>;
  _set?: Maybe<Answered_Questions_Set_Input>;
  where: Answered_Questions_Bool_Exp;
};

export type Mutation_RootUpdate_Answered_Questions_By_PkArgs = {
  _inc?: Maybe<Answered_Questions_Inc_Input>;
  _set?: Maybe<Answered_Questions_Set_Input>;
  pk_columns: Answered_Questions_Pk_Columns_Input;
};

export type Mutation_RootUpdate_AnswersArgs = {
  _inc?: Maybe<Answers_Inc_Input>;
  _set?: Maybe<Answers_Set_Input>;
  where: Answers_Bool_Exp;
};

export type Mutation_RootUpdate_Answers_By_PkArgs = {
  _inc?: Maybe<Answers_Inc_Input>;
  _set?: Maybe<Answers_Set_Input>;
  pk_columns: Answers_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Question_TypesArgs = {
  _set?: Maybe<Question_Types_Set_Input>;
  where: Question_Types_Bool_Exp;
};

export type Mutation_RootUpdate_Question_Types_By_PkArgs = {
  _set?: Maybe<Question_Types_Set_Input>;
  pk_columns: Question_Types_Pk_Columns_Input;
};

export type Mutation_RootUpdate_QuestionsArgs = {
  _inc?: Maybe<Questions_Inc_Input>;
  _set?: Maybe<Questions_Set_Input>;
  where: Questions_Bool_Exp;
};

export type Mutation_RootUpdate_Questions_By_PkArgs = {
  _inc?: Maybe<Questions_Inc_Input>;
  _set?: Maybe<Questions_Set_Input>;
  pk_columns: Questions_Pk_Columns_Input;
};

export type Mutation_RootUpdate_User_GenderArgs = {
  _set?: Maybe<User_Gender_Set_Input>;
  where: User_Gender_Bool_Exp;
};

export type Mutation_RootUpdate_User_Gender_By_PkArgs = {
  _set?: Maybe<User_Gender_Set_Input>;
  pk_columns: User_Gender_Pk_Columns_Input;
};

export type Mutation_RootUpdate_User_RolesArgs = {
  _set?: Maybe<User_Roles_Set_Input>;
  where: User_Roles_Bool_Exp;
};

export type Mutation_RootUpdate_User_Roles_By_PkArgs = {
  _set?: Maybe<User_Roles_Set_Input>;
  pk_columns: User_Roles_Pk_Columns_Input;
};

export type Mutation_RootUpdate_UsersArgs = {
  _inc?: Maybe<Users_Inc_Input>;
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};

export type Mutation_RootUpdate_Users_By_PkArgs = {
  _inc?: Maybe<Users_Inc_Input>;
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Video_RatingsArgs = {
  _inc?: Maybe<Video_Ratings_Inc_Input>;
  _set?: Maybe<Video_Ratings_Set_Input>;
  where: Video_Ratings_Bool_Exp;
};

export type Mutation_RootUpdate_Video_Ratings_By_PkArgs = {
  _inc?: Maybe<Video_Ratings_Inc_Input>;
  _set?: Maybe<Video_Ratings_Set_Input>;
  pk_columns: Video_Ratings_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Video_ViewsArgs = {
  _inc?: Maybe<Video_Views_Inc_Input>;
  _set?: Maybe<Video_Views_Set_Input>;
  where: Video_Views_Bool_Exp;
};

export type Mutation_RootUpdate_Video_Views_By_PkArgs = {
  _inc?: Maybe<Video_Views_Inc_Input>;
  _set?: Maybe<Video_Views_Set_Input>;
  pk_columns: Video_Views_Pk_Columns_Input;
};

export type Mutation_RootUpdate_VideosArgs = {
  _inc?: Maybe<Videos_Inc_Input>;
  _set?: Maybe<Videos_Set_Input>;
  where: Videos_Bool_Exp;
};

export type Mutation_RootUpdate_Videos_By_PkArgs = {
  _inc?: Maybe<Videos_Inc_Input>;
  _set?: Maybe<Videos_Set_Input>;
  pk_columns: Videos_Pk_Columns_Input;
};

export enum Order_By {
  ASC = 'asc',
  ASC_NULLS_FIRST = 'asc_nulls_first',
  ASC_NULLS_LAST = 'asc_nulls_last',
  DESC = 'desc',
  DESC_NULLS_FIRST = 'desc_nulls_first',
  DESC_NULLS_LAST = 'desc_nulls_last',
}

export type Query_Root = {
  __typename: 'query_root';
  answered_question_set_high_score: Array<Answered_Question_Set_High_Score>;
  answered_question_set_high_score_aggregate: Answered_Question_Set_High_Score_Aggregate;
  answered_question_set_score: Array<Answered_Question_Set_Score>;
  answered_question_set_score_aggregate: Answered_Question_Set_Score_Aggregate;
  answered_question_sets: Array<Answered_Question_Sets>;
  answered_question_sets_aggregate: Answered_Question_Sets_Aggregate;
  answered_question_sets_by_pk?: Maybe<Answered_Question_Sets>;
  answered_questions: Array<Answered_Questions>;
  answered_questions_aggregate: Answered_Questions_Aggregate;
  answered_questions_by_pk?: Maybe<Answered_Questions>;
  answers: Array<Answers>;
  answers_aggregate: Answers_Aggregate;
  answers_by_pk?: Maybe<Answers>;
  question_types: Array<Question_Types>;
  question_types_aggregate: Question_Types_Aggregate;
  question_types_by_pk?: Maybe<Question_Types>;
  questions: Array<Questions>;
  questions_aggregate: Questions_Aggregate;
  questions_by_pk?: Maybe<Questions>;
  user_gender: Array<User_Gender>;
  user_gender_aggregate: User_Gender_Aggregate;
  user_gender_by_pk?: Maybe<User_Gender>;
  user_roles: Array<User_Roles>;
  user_roles_aggregate: User_Roles_Aggregate;
  user_roles_by_pk?: Maybe<User_Roles>;
  users: Array<Users>;
  users_aggregate: Users_Aggregate;
  users_by_pk?: Maybe<Users>;
  video_ratings: Array<Video_Ratings>;
  video_ratings_aggregate: Video_Ratings_Aggregate;
  video_ratings_by_pk?: Maybe<Video_Ratings>;
  video_views: Array<Video_Views>;
  video_views_aggregate: Video_Views_Aggregate;
  video_views_by_pk?: Maybe<Video_Views>;
  videos: Array<Videos>;
  videos_aggregate: Videos_Aggregate;
  videos_by_pk?: Maybe<Videos>;
};

export type Query_RootAnswered_Question_Set_High_ScoreArgs = {
  distinct_on?: Maybe<Array<Answered_Question_Set_High_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Question_Set_High_Score_Order_By>>;
  where?: Maybe<Answered_Question_Set_High_Score_Bool_Exp>;
};

export type Query_RootAnswered_Question_Set_High_Score_AggregateArgs = {
  distinct_on?: Maybe<Array<Answered_Question_Set_High_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Question_Set_High_Score_Order_By>>;
  where?: Maybe<Answered_Question_Set_High_Score_Bool_Exp>;
};

export type Query_RootAnswered_Question_Set_ScoreArgs = {
  distinct_on?: Maybe<Array<Answered_Question_Set_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Question_Set_Score_Order_By>>;
  where?: Maybe<Answered_Question_Set_Score_Bool_Exp>;
};

export type Query_RootAnswered_Question_Set_Score_AggregateArgs = {
  distinct_on?: Maybe<Array<Answered_Question_Set_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Question_Set_Score_Order_By>>;
  where?: Maybe<Answered_Question_Set_Score_Bool_Exp>;
};

export type Query_RootAnswered_Question_SetsArgs = {
  distinct_on?: Maybe<Array<Answered_Question_Sets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Question_Sets_Order_By>>;
  where?: Maybe<Answered_Question_Sets_Bool_Exp>;
};

export type Query_RootAnswered_Question_Sets_AggregateArgs = {
  distinct_on?: Maybe<Array<Answered_Question_Sets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Question_Sets_Order_By>>;
  where?: Maybe<Answered_Question_Sets_Bool_Exp>;
};

export type Query_RootAnswered_Question_Sets_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootAnswered_QuestionsArgs = {
  distinct_on?: Maybe<Array<Answered_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Questions_Order_By>>;
  where?: Maybe<Answered_Questions_Bool_Exp>;
};

export type Query_RootAnswered_Questions_AggregateArgs = {
  distinct_on?: Maybe<Array<Answered_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Questions_Order_By>>;
  where?: Maybe<Answered_Questions_Bool_Exp>;
};

export type Query_RootAnswered_Questions_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootAnswersArgs = {
  distinct_on?: Maybe<Array<Answers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answers_Order_By>>;
  where?: Maybe<Answers_Bool_Exp>;
};

export type Query_RootAnswers_AggregateArgs = {
  distinct_on?: Maybe<Array<Answers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answers_Order_By>>;
  where?: Maybe<Answers_Bool_Exp>;
};

export type Query_RootAnswers_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootQuestion_TypesArgs = {
  distinct_on?: Maybe<Array<Question_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Question_Types_Order_By>>;
  where?: Maybe<Question_Types_Bool_Exp>;
};

export type Query_RootQuestion_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Question_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Question_Types_Order_By>>;
  where?: Maybe<Question_Types_Bool_Exp>;
};

export type Query_RootQuestion_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootQuestionsArgs = {
  distinct_on?: Maybe<Array<Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Questions_Order_By>>;
  where?: Maybe<Questions_Bool_Exp>;
};

export type Query_RootQuestions_AggregateArgs = {
  distinct_on?: Maybe<Array<Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Questions_Order_By>>;
  where?: Maybe<Questions_Bool_Exp>;
};

export type Query_RootQuestions_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootUser_GenderArgs = {
  distinct_on?: Maybe<Array<User_Gender_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Gender_Order_By>>;
  where?: Maybe<User_Gender_Bool_Exp>;
};

export type Query_RootUser_Gender_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Gender_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Gender_Order_By>>;
  where?: Maybe<User_Gender_Bool_Exp>;
};

export type Query_RootUser_Gender_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootUser_RolesArgs = {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
};

export type Query_RootUser_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
};

export type Query_RootUser_Roles_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

export type Query_RootUsers_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootVideo_RatingsArgs = {
  distinct_on?: Maybe<Array<Video_Ratings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Video_Ratings_Order_By>>;
  where?: Maybe<Video_Ratings_Bool_Exp>;
};

export type Query_RootVideo_Ratings_AggregateArgs = {
  distinct_on?: Maybe<Array<Video_Ratings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Video_Ratings_Order_By>>;
  where?: Maybe<Video_Ratings_Bool_Exp>;
};

export type Query_RootVideo_Ratings_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootVideo_ViewsArgs = {
  distinct_on?: Maybe<Array<Video_Views_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Video_Views_Order_By>>;
  where?: Maybe<Video_Views_Bool_Exp>;
};

export type Query_RootVideo_Views_AggregateArgs = {
  distinct_on?: Maybe<Array<Video_Views_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Video_Views_Order_By>>;
  where?: Maybe<Video_Views_Bool_Exp>;
};

export type Query_RootVideo_Views_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootVideosArgs = {
  distinct_on?: Maybe<Array<Videos_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Videos_Order_By>>;
  where?: Maybe<Videos_Bool_Exp>;
};

export type Query_RootVideos_AggregateArgs = {
  distinct_on?: Maybe<Array<Videos_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Videos_Order_By>>;
  where?: Maybe<Videos_Bool_Exp>;
};

export type Query_RootVideos_By_PkArgs = {
  id: Scalars['Int'];
};

export type Question_Types = {
  __typename: 'question_types';
  questions: Array<Questions>;
  questions_aggregate: Questions_Aggregate;
  value: Scalars['String'];
};

export type Question_TypesQuestionsArgs = {
  distinct_on?: Maybe<Array<Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Questions_Order_By>>;
  where?: Maybe<Questions_Bool_Exp>;
};

export type Question_TypesQuestions_AggregateArgs = {
  distinct_on?: Maybe<Array<Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Questions_Order_By>>;
  where?: Maybe<Questions_Bool_Exp>;
};

export type Question_Types_Aggregate = {
  __typename: 'question_types_aggregate';
  aggregate?: Maybe<Question_Types_Aggregate_Fields>;
  nodes: Array<Question_Types>;
};

export type Question_Types_Aggregate_Fields = {
  __typename: 'question_types_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Question_Types_Max_Fields>;
  min?: Maybe<Question_Types_Min_Fields>;
};

export type Question_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Question_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type Question_Types_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Question_Types_Max_Order_By>;
  min?: Maybe<Question_Types_Min_Order_By>;
};

export type Question_Types_Arr_Rel_Insert_Input = {
  data: Array<Question_Types_Insert_Input>;
  on_conflict?: Maybe<Question_Types_On_Conflict>;
};

export type Question_Types_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Question_Types_Bool_Exp>>>;
  _not?: Maybe<Question_Types_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Question_Types_Bool_Exp>>>;
  questions?: Maybe<Questions_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

export enum Question_Types_Constraint {
  QUESTION_TYPES_PKEY = 'question_types_pkey',
}

export enum Question_Types_Enum {
  OFFER = 'OFFER',
  SURVEY = 'SURVEY',
  VIDEO = 'VIDEO',
}

export type Question_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Question_Types_Enum>;
  _in?: Maybe<Array<Question_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Question_Types_Enum>;
  _nin?: Maybe<Array<Question_Types_Enum>>;
};

export type Question_Types_Insert_Input = {
  questions?: Maybe<Questions_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

export type Question_Types_Max_Fields = {
  __typename: 'question_types_max_fields';
  value?: Maybe<Scalars['String']>;
};

export type Question_Types_Max_Order_By = {
  value?: Maybe<Order_By>;
};

export type Question_Types_Min_Fields = {
  __typename: 'question_types_min_fields';
  value?: Maybe<Scalars['String']>;
};

export type Question_Types_Min_Order_By = {
  value?: Maybe<Order_By>;
};

export type Question_Types_Mutation_Response = {
  __typename: 'question_types_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Question_Types>;
};

export type Question_Types_Obj_Rel_Insert_Input = {
  data: Question_Types_Insert_Input;
  on_conflict?: Maybe<Question_Types_On_Conflict>;
};

export type Question_Types_On_Conflict = {
  constraint: Question_Types_Constraint;
  update_columns: Array<Question_Types_Update_Column>;
  where?: Maybe<Question_Types_Bool_Exp>;
};

export type Question_Types_Order_By = {
  questions_aggregate?: Maybe<Questions_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

export type Question_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

export enum Question_Types_Select_Column {
  VALUE = 'value',
}

export type Question_Types_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

export enum Question_Types_Update_Column {
  VALUE = 'value',
}

export type Questions = {
  __typename: 'questions';
  answered_questions: Array<Answered_Questions>;
  answered_questions_aggregate: Answered_Questions_Aggregate;
  answers: Array<Answers>;
  answers_aggregate: Answers_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  question_type: Question_Types;
  tagline: Scalars['String'];
  text: Scalars['String'];
  type: Question_Types_Enum;
  video: Videos;
  video_id: Scalars['Int'];
};

export type QuestionsAnswered_QuestionsArgs = {
  distinct_on?: Maybe<Array<Answered_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Questions_Order_By>>;
  where?: Maybe<Answered_Questions_Bool_Exp>;
};

export type QuestionsAnswered_Questions_AggregateArgs = {
  distinct_on?: Maybe<Array<Answered_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Questions_Order_By>>;
  where?: Maybe<Answered_Questions_Bool_Exp>;
};

export type QuestionsAnswersArgs = {
  distinct_on?: Maybe<Array<Answers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answers_Order_By>>;
  where?: Maybe<Answers_Bool_Exp>;
};

export type QuestionsAnswers_AggregateArgs = {
  distinct_on?: Maybe<Array<Answers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answers_Order_By>>;
  where?: Maybe<Answers_Bool_Exp>;
};

export type Questions_Aggregate = {
  __typename: 'questions_aggregate';
  aggregate?: Maybe<Questions_Aggregate_Fields>;
  nodes: Array<Questions>;
};

export type Questions_Aggregate_Fields = {
  __typename: 'questions_aggregate_fields';
  avg?: Maybe<Questions_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Questions_Max_Fields>;
  min?: Maybe<Questions_Min_Fields>;
  stddev?: Maybe<Questions_Stddev_Fields>;
  stddev_pop?: Maybe<Questions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Questions_Stddev_Samp_Fields>;
  sum?: Maybe<Questions_Sum_Fields>;
  var_pop?: Maybe<Questions_Var_Pop_Fields>;
  var_samp?: Maybe<Questions_Var_Samp_Fields>;
  variance?: Maybe<Questions_Variance_Fields>;
};

export type Questions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Questions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type Questions_Aggregate_Order_By = {
  avg?: Maybe<Questions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Questions_Max_Order_By>;
  min?: Maybe<Questions_Min_Order_By>;
  stddev?: Maybe<Questions_Stddev_Order_By>;
  stddev_pop?: Maybe<Questions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Questions_Stddev_Samp_Order_By>;
  sum?: Maybe<Questions_Sum_Order_By>;
  var_pop?: Maybe<Questions_Var_Pop_Order_By>;
  var_samp?: Maybe<Questions_Var_Samp_Order_By>;
  variance?: Maybe<Questions_Variance_Order_By>;
};

export type Questions_Arr_Rel_Insert_Input = {
  data: Array<Questions_Insert_Input>;
  on_conflict?: Maybe<Questions_On_Conflict>;
};

export type Questions_Avg_Fields = {
  __typename: 'questions_avg_fields';
  id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Questions_Avg_Order_By = {
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Questions_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Questions_Bool_Exp>>>;
  _not?: Maybe<Questions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Questions_Bool_Exp>>>;
  answered_questions?: Maybe<Answered_Questions_Bool_Exp>;
  answers?: Maybe<Answers_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  question_type?: Maybe<Question_Types_Bool_Exp>;
  tagline?: Maybe<String_Comparison_Exp>;
  text?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Question_Types_Enum_Comparison_Exp>;
  video?: Maybe<Videos_Bool_Exp>;
  video_id?: Maybe<Int_Comparison_Exp>;
};

export enum Questions_Constraint {
  QUESTIONS_PKEY = 'questions_pkey',
}

export type Questions_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Questions_Insert_Input = {
  answered_questions?: Maybe<Answered_Questions_Arr_Rel_Insert_Input>;
  answers?: Maybe<Answers_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  question_type?: Maybe<Question_Types_Obj_Rel_Insert_Input>;
  tagline?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Question_Types_Enum>;
  video?: Maybe<Videos_Obj_Rel_Insert_Input>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Questions_Max_Fields = {
  __typename: 'questions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  tagline?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Questions_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tagline?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Questions_Min_Fields = {
  __typename: 'questions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  tagline?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Questions_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tagline?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Questions_Mutation_Response = {
  __typename: 'questions_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Questions>;
};

export type Questions_Obj_Rel_Insert_Input = {
  data: Questions_Insert_Input;
  on_conflict?: Maybe<Questions_On_Conflict>;
};

export type Questions_On_Conflict = {
  constraint: Questions_Constraint;
  update_columns: Array<Questions_Update_Column>;
  where?: Maybe<Questions_Bool_Exp>;
};

export type Questions_Order_By = {
  answered_questions_aggregate?: Maybe<Answered_Questions_Aggregate_Order_By>;
  answers_aggregate?: Maybe<Answers_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question_type?: Maybe<Question_Types_Order_By>;
  tagline?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  video?: Maybe<Videos_Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Questions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Questions_Select_Column {
  CREATED_AT = 'created_at',
  ID = 'id',
  TAGLINE = 'tagline',
  TEXT = 'text',
  TYPE = 'type',
  VIDEO_ID = 'video_id',
}

export type Questions_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  tagline?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Question_Types_Enum>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Questions_Stddev_Fields = {
  __typename: 'questions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Questions_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Questions_Stddev_Pop_Fields = {
  __typename: 'questions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Questions_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Questions_Stddev_Samp_Fields = {
  __typename: 'questions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Questions_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Questions_Sum_Fields = {
  __typename: 'questions_sum_fields';
  id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Questions_Sum_Order_By = {
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export enum Questions_Update_Column {
  CREATED_AT = 'created_at',
  ID = 'id',
  TAGLINE = 'tagline',
  TEXT = 'text',
  TYPE = 'type',
  VIDEO_ID = 'video_id',
}

export type Questions_Var_Pop_Fields = {
  __typename: 'questions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Questions_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Questions_Var_Samp_Fields = {
  __typename: 'questions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Questions_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Questions_Variance_Fields = {
  __typename: 'questions_variance_fields';
  id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Questions_Variance_Order_By = {
  id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export enum Register_User_Gender_Enum {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export type RegistrationInput = {
  birthDate: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: Maybe<Register_User_Gender_Enum>;
  password: Scalars['String'];
};

export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

export type Subscription_Root = {
  __typename: 'subscription_root';
  answered_question_set_high_score: Array<Answered_Question_Set_High_Score>;
  answered_question_set_high_score_aggregate: Answered_Question_Set_High_Score_Aggregate;
  answered_question_set_score: Array<Answered_Question_Set_Score>;
  answered_question_set_score_aggregate: Answered_Question_Set_Score_Aggregate;
  answered_question_sets: Array<Answered_Question_Sets>;
  answered_question_sets_aggregate: Answered_Question_Sets_Aggregate;
  answered_question_sets_by_pk?: Maybe<Answered_Question_Sets>;
  answered_questions: Array<Answered_Questions>;
  answered_questions_aggregate: Answered_Questions_Aggregate;
  answered_questions_by_pk?: Maybe<Answered_Questions>;
  answers: Array<Answers>;
  answers_aggregate: Answers_Aggregate;
  answers_by_pk?: Maybe<Answers>;
  question_types: Array<Question_Types>;
  question_types_aggregate: Question_Types_Aggregate;
  question_types_by_pk?: Maybe<Question_Types>;
  questions: Array<Questions>;
  questions_aggregate: Questions_Aggregate;
  questions_by_pk?: Maybe<Questions>;
  user_gender: Array<User_Gender>;
  user_gender_aggregate: User_Gender_Aggregate;
  user_gender_by_pk?: Maybe<User_Gender>;
  user_roles: Array<User_Roles>;
  user_roles_aggregate: User_Roles_Aggregate;
  user_roles_by_pk?: Maybe<User_Roles>;
  users: Array<Users>;
  users_aggregate: Users_Aggregate;
  users_by_pk?: Maybe<Users>;
  video_ratings: Array<Video_Ratings>;
  video_ratings_aggregate: Video_Ratings_Aggregate;
  video_ratings_by_pk?: Maybe<Video_Ratings>;
  video_views: Array<Video_Views>;
  video_views_aggregate: Video_Views_Aggregate;
  video_views_by_pk?: Maybe<Video_Views>;
  videos: Array<Videos>;
  videos_aggregate: Videos_Aggregate;
  videos_by_pk?: Maybe<Videos>;
};

export type Subscription_RootAnswered_Question_Set_High_ScoreArgs = {
  distinct_on?: Maybe<Array<Answered_Question_Set_High_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Question_Set_High_Score_Order_By>>;
  where?: Maybe<Answered_Question_Set_High_Score_Bool_Exp>;
};

export type Subscription_RootAnswered_Question_Set_High_Score_AggregateArgs = {
  distinct_on?: Maybe<Array<Answered_Question_Set_High_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Question_Set_High_Score_Order_By>>;
  where?: Maybe<Answered_Question_Set_High_Score_Bool_Exp>;
};

export type Subscription_RootAnswered_Question_Set_ScoreArgs = {
  distinct_on?: Maybe<Array<Answered_Question_Set_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Question_Set_Score_Order_By>>;
  where?: Maybe<Answered_Question_Set_Score_Bool_Exp>;
};

export type Subscription_RootAnswered_Question_Set_Score_AggregateArgs = {
  distinct_on?: Maybe<Array<Answered_Question_Set_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Question_Set_Score_Order_By>>;
  where?: Maybe<Answered_Question_Set_Score_Bool_Exp>;
};

export type Subscription_RootAnswered_Question_SetsArgs = {
  distinct_on?: Maybe<Array<Answered_Question_Sets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Question_Sets_Order_By>>;
  where?: Maybe<Answered_Question_Sets_Bool_Exp>;
};

export type Subscription_RootAnswered_Question_Sets_AggregateArgs = {
  distinct_on?: Maybe<Array<Answered_Question_Sets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Question_Sets_Order_By>>;
  where?: Maybe<Answered_Question_Sets_Bool_Exp>;
};

export type Subscription_RootAnswered_Question_Sets_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootAnswered_QuestionsArgs = {
  distinct_on?: Maybe<Array<Answered_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Questions_Order_By>>;
  where?: Maybe<Answered_Questions_Bool_Exp>;
};

export type Subscription_RootAnswered_Questions_AggregateArgs = {
  distinct_on?: Maybe<Array<Answered_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Questions_Order_By>>;
  where?: Maybe<Answered_Questions_Bool_Exp>;
};

export type Subscription_RootAnswered_Questions_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootAnswersArgs = {
  distinct_on?: Maybe<Array<Answers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answers_Order_By>>;
  where?: Maybe<Answers_Bool_Exp>;
};

export type Subscription_RootAnswers_AggregateArgs = {
  distinct_on?: Maybe<Array<Answers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answers_Order_By>>;
  where?: Maybe<Answers_Bool_Exp>;
};

export type Subscription_RootAnswers_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootQuestion_TypesArgs = {
  distinct_on?: Maybe<Array<Question_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Question_Types_Order_By>>;
  where?: Maybe<Question_Types_Bool_Exp>;
};

export type Subscription_RootQuestion_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Question_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Question_Types_Order_By>>;
  where?: Maybe<Question_Types_Bool_Exp>;
};

export type Subscription_RootQuestion_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootQuestionsArgs = {
  distinct_on?: Maybe<Array<Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Questions_Order_By>>;
  where?: Maybe<Questions_Bool_Exp>;
};

export type Subscription_RootQuestions_AggregateArgs = {
  distinct_on?: Maybe<Array<Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Questions_Order_By>>;
  where?: Maybe<Questions_Bool_Exp>;
};

export type Subscription_RootQuestions_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootUser_GenderArgs = {
  distinct_on?: Maybe<Array<User_Gender_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Gender_Order_By>>;
  where?: Maybe<User_Gender_Bool_Exp>;
};

export type Subscription_RootUser_Gender_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Gender_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Gender_Order_By>>;
  where?: Maybe<User_Gender_Bool_Exp>;
};

export type Subscription_RootUser_Gender_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootUser_RolesArgs = {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
};

export type Subscription_RootUser_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
};

export type Subscription_RootUser_Roles_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootVideo_RatingsArgs = {
  distinct_on?: Maybe<Array<Video_Ratings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Video_Ratings_Order_By>>;
  where?: Maybe<Video_Ratings_Bool_Exp>;
};

export type Subscription_RootVideo_Ratings_AggregateArgs = {
  distinct_on?: Maybe<Array<Video_Ratings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Video_Ratings_Order_By>>;
  where?: Maybe<Video_Ratings_Bool_Exp>;
};

export type Subscription_RootVideo_Ratings_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootVideo_ViewsArgs = {
  distinct_on?: Maybe<Array<Video_Views_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Video_Views_Order_By>>;
  where?: Maybe<Video_Views_Bool_Exp>;
};

export type Subscription_RootVideo_Views_AggregateArgs = {
  distinct_on?: Maybe<Array<Video_Views_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Video_Views_Order_By>>;
  where?: Maybe<Video_Views_Bool_Exp>;
};

export type Subscription_RootVideo_Views_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootVideosArgs = {
  distinct_on?: Maybe<Array<Videos_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Videos_Order_By>>;
  where?: Maybe<Videos_Bool_Exp>;
};

export type Subscription_RootVideos_AggregateArgs = {
  distinct_on?: Maybe<Array<Videos_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Videos_Order_By>>;
  where?: Maybe<Videos_Bool_Exp>;
};

export type Subscription_RootVideos_By_PkArgs = {
  id: Scalars['Int'];
};

export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

export type User_Gender = {
  __typename: 'user_gender';
  value: Scalars['String'];
};

export type User_Gender_Aggregate = {
  __typename: 'user_gender_aggregate';
  aggregate?: Maybe<User_Gender_Aggregate_Fields>;
  nodes: Array<User_Gender>;
};

export type User_Gender_Aggregate_Fields = {
  __typename: 'user_gender_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Gender_Max_Fields>;
  min?: Maybe<User_Gender_Min_Fields>;
};

export type User_Gender_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Gender_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type User_Gender_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Gender_Max_Order_By>;
  min?: Maybe<User_Gender_Min_Order_By>;
};

export type User_Gender_Arr_Rel_Insert_Input = {
  data: Array<User_Gender_Insert_Input>;
  on_conflict?: Maybe<User_Gender_On_Conflict>;
};

export type User_Gender_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Gender_Bool_Exp>>>;
  _not?: Maybe<User_Gender_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Gender_Bool_Exp>>>;
  value?: Maybe<String_Comparison_Exp>;
};

export enum User_Gender_Constraint {
  USER_GENDER_PKEY = 'user_gender_pkey',
}

export enum User_Gender_Enum {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export type User_Gender_Enum_Comparison_Exp = {
  _eq?: Maybe<User_Gender_Enum>;
  _in?: Maybe<Array<User_Gender_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<User_Gender_Enum>;
  _nin?: Maybe<Array<User_Gender_Enum>>;
};

export type User_Gender_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

export type User_Gender_Max_Fields = {
  __typename: 'user_gender_max_fields';
  value?: Maybe<Scalars['String']>;
};

export type User_Gender_Max_Order_By = {
  value?: Maybe<Order_By>;
};

export type User_Gender_Min_Fields = {
  __typename: 'user_gender_min_fields';
  value?: Maybe<Scalars['String']>;
};

export type User_Gender_Min_Order_By = {
  value?: Maybe<Order_By>;
};

export type User_Gender_Mutation_Response = {
  __typename: 'user_gender_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<User_Gender>;
};

export type User_Gender_Obj_Rel_Insert_Input = {
  data: User_Gender_Insert_Input;
  on_conflict?: Maybe<User_Gender_On_Conflict>;
};

export type User_Gender_On_Conflict = {
  constraint: User_Gender_Constraint;
  update_columns: Array<User_Gender_Update_Column>;
  where?: Maybe<User_Gender_Bool_Exp>;
};

export type User_Gender_Order_By = {
  value?: Maybe<Order_By>;
};

export type User_Gender_Pk_Columns_Input = {
  value: Scalars['String'];
};

export enum User_Gender_Select_Column {
  VALUE = 'value',
}

export type User_Gender_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

export enum User_Gender_Update_Column {
  VALUE = 'value',
}

export type User_Roles = {
  __typename: 'user_roles';
  value: Scalars['String'];
};

export type User_Roles_Aggregate = {
  __typename: 'user_roles_aggregate';
  aggregate?: Maybe<User_Roles_Aggregate_Fields>;
  nodes: Array<User_Roles>;
};

export type User_Roles_Aggregate_Fields = {
  __typename: 'user_roles_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Roles_Max_Fields>;
  min?: Maybe<User_Roles_Min_Fields>;
};

export type User_Roles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Roles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type User_Roles_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Roles_Max_Order_By>;
  min?: Maybe<User_Roles_Min_Order_By>;
};

export type User_Roles_Arr_Rel_Insert_Input = {
  data: Array<User_Roles_Insert_Input>;
  on_conflict?: Maybe<User_Roles_On_Conflict>;
};

export type User_Roles_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Roles_Bool_Exp>>>;
  _not?: Maybe<User_Roles_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Roles_Bool_Exp>>>;
  value?: Maybe<String_Comparison_Exp>;
};

export enum User_Roles_Constraint {
  USER_ROLES_PKEY = 'user_roles_pkey',
}

export enum User_Roles_Enum {
  SUPER_USER = 'super_user',
  UNKNOWN_USER = 'unknown_user',
  USER = 'user',
}

export type User_Roles_Enum_Comparison_Exp = {
  _eq?: Maybe<User_Roles_Enum>;
  _in?: Maybe<Array<User_Roles_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<User_Roles_Enum>;
  _nin?: Maybe<Array<User_Roles_Enum>>;
};

export type User_Roles_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

export type User_Roles_Max_Fields = {
  __typename: 'user_roles_max_fields';
  value?: Maybe<Scalars['String']>;
};

export type User_Roles_Max_Order_By = {
  value?: Maybe<Order_By>;
};

export type User_Roles_Min_Fields = {
  __typename: 'user_roles_min_fields';
  value?: Maybe<Scalars['String']>;
};

export type User_Roles_Min_Order_By = {
  value?: Maybe<Order_By>;
};

export type User_Roles_Mutation_Response = {
  __typename: 'user_roles_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<User_Roles>;
};

export type User_Roles_Obj_Rel_Insert_Input = {
  data: User_Roles_Insert_Input;
  on_conflict?: Maybe<User_Roles_On_Conflict>;
};

export type User_Roles_On_Conflict = {
  constraint: User_Roles_Constraint;
  update_columns: Array<User_Roles_Update_Column>;
  where?: Maybe<User_Roles_Bool_Exp>;
};

export type User_Roles_Order_By = {
  value?: Maybe<Order_By>;
};

export type User_Roles_Pk_Columns_Input = {
  value: Scalars['String'];
};

export enum User_Roles_Select_Column {
  VALUE = 'value',
}

export type User_Roles_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

export enum User_Roles_Update_Column {
  VALUE = 'value',
}

export type Users = {
  __typename: 'users';
  answered_questions: Array<Answered_Questions>;
  answered_questions_aggregate: Answered_Questions_Aggregate;
  birth_date?: Maybe<Scalars['date']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  email?: Maybe<Scalars['String']>;
  email_opt_in: Scalars['Boolean'];
  first_name: Scalars['String'];
  gender?: Maybe<User_Gender_Enum>;
  id: Scalars['Int'];
  is_active: Scalars['Boolean'];
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  password_reset_key?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  referral?: Maybe<Scalars['String']>;
  role: User_Roles_Enum;
  screen_name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streeet_address?: Maybe<Scalars['String']>;
  street_address_2?: Maybe<Scalars['String']>;
  video_ratings: Array<Video_Ratings>;
  video_ratings_aggregate: Video_Ratings_Aggregate;
  zip?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

export type UsersAnswered_QuestionsArgs = {
  distinct_on?: Maybe<Array<Answered_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Questions_Order_By>>;
  where?: Maybe<Answered_Questions_Bool_Exp>;
};

export type UsersAnswered_Questions_AggregateArgs = {
  distinct_on?: Maybe<Array<Answered_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Questions_Order_By>>;
  where?: Maybe<Answered_Questions_Bool_Exp>;
};

export type UsersVideo_RatingsArgs = {
  distinct_on?: Maybe<Array<Video_Ratings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Video_Ratings_Order_By>>;
  where?: Maybe<Video_Ratings_Bool_Exp>;
};

export type UsersVideo_Ratings_AggregateArgs = {
  distinct_on?: Maybe<Array<Video_Ratings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Video_Ratings_Order_By>>;
  where?: Maybe<Video_Ratings_Bool_Exp>;
};

export type Users_Aggregate = {
  __typename: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

export type Users_Aggregate_Fields = {
  __typename: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};

export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type Users_Aggregate_Order_By = {
  avg?: Maybe<Users_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Users_Max_Order_By>;
  min?: Maybe<Users_Min_Order_By>;
  stddev?: Maybe<Users_Stddev_Order_By>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Order_By>;
  sum?: Maybe<Users_Sum_Order_By>;
  var_pop?: Maybe<Users_Var_Pop_Order_By>;
  var_samp?: Maybe<Users_Var_Samp_Order_By>;
  variance?: Maybe<Users_Variance_Order_By>;
};

export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};

export type Users_Avg_Fields = {
  __typename: 'users_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Users_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

export type Users_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  answered_questions?: Maybe<Answered_Questions_Bool_Exp>;
  birth_date?: Maybe<Date_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  email_opt_in?: Maybe<Boolean_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  gender?: Maybe<User_Gender_Enum_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_active?: Maybe<Boolean_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  password?: Maybe<String_Comparison_Exp>;
  password_reset_key?: Maybe<String_Comparison_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  referral?: Maybe<String_Comparison_Exp>;
  role?: Maybe<User_Roles_Enum_Comparison_Exp>;
  screen_name?: Maybe<String_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  streeet_address?: Maybe<String_Comparison_Exp>;
  street_address_2?: Maybe<String_Comparison_Exp>;
  video_ratings?: Maybe<Video_Ratings_Bool_Exp>;
  zip?: Maybe<String_Comparison_Exp>;
  zip_code?: Maybe<String_Comparison_Exp>;
};

export enum Users_Constraint {
  USERS_PKEY = 'users_pkey',
}

export type Users_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

export type Users_Insert_Input = {
  answered_questions?: Maybe<Answered_Questions_Arr_Rel_Insert_Input>;
  birth_date?: Maybe<Scalars['date']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_opt_in?: Maybe<Scalars['Boolean']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<User_Gender_Enum>;
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  password_reset_key?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  referral?: Maybe<Scalars['String']>;
  role?: Maybe<User_Roles_Enum>;
  screen_name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streeet_address?: Maybe<Scalars['String']>;
  street_address_2?: Maybe<Scalars['String']>;
  video_ratings?: Maybe<Video_Ratings_Arr_Rel_Insert_Input>;
  zip?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

export type Users_Max_Fields = {
  __typename: 'users_max_fields';
  birth_date?: Maybe<Scalars['date']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  password_reset_key?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  referral?: Maybe<Scalars['String']>;
  screen_name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streeet_address?: Maybe<Scalars['String']>;
  street_address_2?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

export type Users_Max_Order_By = {
  birth_date?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  password_reset_key?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  referral?: Maybe<Order_By>;
  screen_name?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  streeet_address?: Maybe<Order_By>;
  street_address_2?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

export type Users_Min_Fields = {
  __typename: 'users_min_fields';
  birth_date?: Maybe<Scalars['date']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  password_reset_key?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  referral?: Maybe<Scalars['String']>;
  screen_name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streeet_address?: Maybe<Scalars['String']>;
  street_address_2?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

export type Users_Min_Order_By = {
  birth_date?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  password_reset_key?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  referral?: Maybe<Order_By>;
  screen_name?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  streeet_address?: Maybe<Order_By>;
  street_address_2?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

export type Users_Mutation_Response = {
  __typename: 'users_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Users>;
};

export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};

export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

export type Users_Order_By = {
  answered_questions_aggregate?: Maybe<Answered_Questions_Aggregate_Order_By>;
  birth_date?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email_opt_in?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  gender?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  password_reset_key?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  referral?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  screen_name?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  streeet_address?: Maybe<Order_By>;
  street_address_2?: Maybe<Order_By>;
  video_ratings_aggregate?: Maybe<Video_Ratings_Aggregate_Order_By>;
  zip?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

export type Users_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Users_Select_Column {
  BIRTH_DATE = 'birth_date',
  CITY = 'city',
  COUNTRY = 'country',
  CREATED_AT = 'created_at',
  EMAIL = 'email',
  EMAIL_OPT_IN = 'email_opt_in',
  FIRST_NAME = 'first_name',
  GENDER = 'gender',
  ID = 'id',
  IS_ACTIVE = 'is_active',
  LAST_NAME = 'last_name',
  PASSWORD = 'password',
  PASSWORD_RESET_KEY = 'password_reset_key',
  PHONE_NUMBER = 'phone_number',
  REFERRAL = 'referral',
  ROLE = 'role',
  SCREEN_NAME = 'screen_name',
  STATE = 'state',
  STREEET_ADDRESS = 'streeet_address',
  STREET_ADDRESS_2 = 'street_address_2',
  ZIP = 'zip',
  ZIP_CODE = 'zip_code',
}

export type Users_Set_Input = {
  birth_date?: Maybe<Scalars['date']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_opt_in?: Maybe<Scalars['Boolean']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<User_Gender_Enum>;
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  password_reset_key?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  referral?: Maybe<Scalars['String']>;
  role?: Maybe<User_Roles_Enum>;
  screen_name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streeet_address?: Maybe<Scalars['String']>;
  street_address_2?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

export type Users_Stddev_Fields = {
  __typename: 'users_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Users_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

export type Users_Stddev_Pop_Fields = {
  __typename: 'users_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Users_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

export type Users_Stddev_Samp_Fields = {
  __typename: 'users_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Users_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

export type Users_Sum_Fields = {
  __typename: 'users_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type Users_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

export enum Users_Update_Column {
  BIRTH_DATE = 'birth_date',
  CITY = 'city',
  COUNTRY = 'country',
  CREATED_AT = 'created_at',
  EMAIL = 'email',
  EMAIL_OPT_IN = 'email_opt_in',
  FIRST_NAME = 'first_name',
  GENDER = 'gender',
  ID = 'id',
  IS_ACTIVE = 'is_active',
  LAST_NAME = 'last_name',
  PASSWORD = 'password',
  PASSWORD_RESET_KEY = 'password_reset_key',
  PHONE_NUMBER = 'phone_number',
  REFERRAL = 'referral',
  ROLE = 'role',
  SCREEN_NAME = 'screen_name',
  STATE = 'state',
  STREEET_ADDRESS = 'streeet_address',
  STREET_ADDRESS_2 = 'street_address_2',
  ZIP = 'zip',
  ZIP_CODE = 'zip_code',
}

export type Users_Var_Pop_Fields = {
  __typename: 'users_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Users_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

export type Users_Var_Samp_Fields = {
  __typename: 'users_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Users_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

export type Users_Variance_Fields = {
  __typename: 'users_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Users_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

export type Video_Ratings = {
  __typename: 'video_ratings';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  user: Users;
  user_id: Scalars['Int'];
  value: Scalars['Int'];
  video: Videos;
  video_id: Scalars['Int'];
};

export type Video_Ratings_Aggregate = {
  __typename: 'video_ratings_aggregate';
  aggregate?: Maybe<Video_Ratings_Aggregate_Fields>;
  nodes: Array<Video_Ratings>;
};

export type Video_Ratings_Aggregate_Fields = {
  __typename: 'video_ratings_aggregate_fields';
  avg?: Maybe<Video_Ratings_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Video_Ratings_Max_Fields>;
  min?: Maybe<Video_Ratings_Min_Fields>;
  stddev?: Maybe<Video_Ratings_Stddev_Fields>;
  stddev_pop?: Maybe<Video_Ratings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Video_Ratings_Stddev_Samp_Fields>;
  sum?: Maybe<Video_Ratings_Sum_Fields>;
  var_pop?: Maybe<Video_Ratings_Var_Pop_Fields>;
  var_samp?: Maybe<Video_Ratings_Var_Samp_Fields>;
  variance?: Maybe<Video_Ratings_Variance_Fields>;
};

export type Video_Ratings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Video_Ratings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type Video_Ratings_Aggregate_Order_By = {
  avg?: Maybe<Video_Ratings_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Video_Ratings_Max_Order_By>;
  min?: Maybe<Video_Ratings_Min_Order_By>;
  stddev?: Maybe<Video_Ratings_Stddev_Order_By>;
  stddev_pop?: Maybe<Video_Ratings_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Video_Ratings_Stddev_Samp_Order_By>;
  sum?: Maybe<Video_Ratings_Sum_Order_By>;
  var_pop?: Maybe<Video_Ratings_Var_Pop_Order_By>;
  var_samp?: Maybe<Video_Ratings_Var_Samp_Order_By>;
  variance?: Maybe<Video_Ratings_Variance_Order_By>;
};

export type Video_Ratings_Arr_Rel_Insert_Input = {
  data: Array<Video_Ratings_Insert_Input>;
  on_conflict?: Maybe<Video_Ratings_On_Conflict>;
};

export type Video_Ratings_Avg_Fields = {
  __typename: 'video_ratings_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Video_Ratings_Avg_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Video_Ratings_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Video_Ratings_Bool_Exp>>>;
  _not?: Maybe<Video_Ratings_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Video_Ratings_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
  value?: Maybe<Int_Comparison_Exp>;
  video?: Maybe<Videos_Bool_Exp>;
  video_id?: Maybe<Int_Comparison_Exp>;
};

export enum Video_Ratings_Constraint {
  VIDEO_RATINGS_PKEY = 'video_ratings_pkey',
}

export type Video_Ratings_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Video_Ratings_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
  video?: Maybe<Videos_Obj_Rel_Insert_Input>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Video_Ratings_Max_Fields = {
  __typename: 'video_ratings_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Video_Ratings_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Video_Ratings_Min_Fields = {
  __typename: 'video_ratings_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Video_Ratings_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Video_Ratings_Mutation_Response = {
  __typename: 'video_ratings_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Video_Ratings>;
};

export type Video_Ratings_Obj_Rel_Insert_Input = {
  data: Video_Ratings_Insert_Input;
  on_conflict?: Maybe<Video_Ratings_On_Conflict>;
};

export type Video_Ratings_On_Conflict = {
  constraint: Video_Ratings_Constraint;
  update_columns: Array<Video_Ratings_Update_Column>;
  where?: Maybe<Video_Ratings_Bool_Exp>;
};

export type Video_Ratings_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  video?: Maybe<Videos_Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Video_Ratings_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Video_Ratings_Select_Column {
  CREATED_AT = 'created_at',
  ID = 'id',
  USER_ID = 'user_id',
  VALUE = 'value',
  VIDEO_ID = 'video_id',
}

export type Video_Ratings_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Video_Ratings_Stddev_Fields = {
  __typename: 'video_ratings_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Video_Ratings_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Video_Ratings_Stddev_Pop_Fields = {
  __typename: 'video_ratings_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Video_Ratings_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Video_Ratings_Stddev_Samp_Fields = {
  __typename: 'video_ratings_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Video_Ratings_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Video_Ratings_Sum_Fields = {
  __typename: 'video_ratings_sum_fields';
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Video_Ratings_Sum_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export enum Video_Ratings_Update_Column {
  CREATED_AT = 'created_at',
  ID = 'id',
  USER_ID = 'user_id',
  VALUE = 'value',
  VIDEO_ID = 'video_id',
}

export type Video_Ratings_Var_Pop_Fields = {
  __typename: 'video_ratings_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Video_Ratings_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Video_Ratings_Var_Samp_Fields = {
  __typename: 'video_ratings_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Video_Ratings_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Video_Ratings_Variance_Fields = {
  __typename: 'video_ratings_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Video_Ratings_Variance_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Video_Views = {
  __typename: 'video_views';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  user_id: Scalars['Int'];
  video_id: Scalars['Int'];
};

export type Video_Views_Aggregate = {
  __typename: 'video_views_aggregate';
  aggregate?: Maybe<Video_Views_Aggregate_Fields>;
  nodes: Array<Video_Views>;
};

export type Video_Views_Aggregate_Fields = {
  __typename: 'video_views_aggregate_fields';
  avg?: Maybe<Video_Views_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Video_Views_Max_Fields>;
  min?: Maybe<Video_Views_Min_Fields>;
  stddev?: Maybe<Video_Views_Stddev_Fields>;
  stddev_pop?: Maybe<Video_Views_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Video_Views_Stddev_Samp_Fields>;
  sum?: Maybe<Video_Views_Sum_Fields>;
  var_pop?: Maybe<Video_Views_Var_Pop_Fields>;
  var_samp?: Maybe<Video_Views_Var_Samp_Fields>;
  variance?: Maybe<Video_Views_Variance_Fields>;
};

export type Video_Views_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Video_Views_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type Video_Views_Aggregate_Order_By = {
  avg?: Maybe<Video_Views_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Video_Views_Max_Order_By>;
  min?: Maybe<Video_Views_Min_Order_By>;
  stddev?: Maybe<Video_Views_Stddev_Order_By>;
  stddev_pop?: Maybe<Video_Views_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Video_Views_Stddev_Samp_Order_By>;
  sum?: Maybe<Video_Views_Sum_Order_By>;
  var_pop?: Maybe<Video_Views_Var_Pop_Order_By>;
  var_samp?: Maybe<Video_Views_Var_Samp_Order_By>;
  variance?: Maybe<Video_Views_Variance_Order_By>;
};

export type Video_Views_Arr_Rel_Insert_Input = {
  data: Array<Video_Views_Insert_Input>;
  on_conflict?: Maybe<Video_Views_On_Conflict>;
};

export type Video_Views_Avg_Fields = {
  __typename: 'video_views_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Video_Views_Avg_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Video_Views_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Video_Views_Bool_Exp>>>;
  _not?: Maybe<Video_Views_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Video_Views_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
  video_id?: Maybe<Int_Comparison_Exp>;
};

export enum Video_Views_Constraint {
  VIDEO_VIEWS_ID_KEY1 = 'video_views_id_key1',
  VIDEO_VIEWS_PKEY = 'video_views_pkey',
}

export type Video_Views_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Video_Views_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Video_Views_Max_Fields = {
  __typename: 'video_views_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Video_Views_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Video_Views_Min_Fields = {
  __typename: 'video_views_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Video_Views_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Video_Views_Mutation_Response = {
  __typename: 'video_views_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Video_Views>;
};

export type Video_Views_Obj_Rel_Insert_Input = {
  data: Video_Views_Insert_Input;
  on_conflict?: Maybe<Video_Views_On_Conflict>;
};

export type Video_Views_On_Conflict = {
  constraint: Video_Views_Constraint;
  update_columns: Array<Video_Views_Update_Column>;
  where?: Maybe<Video_Views_Bool_Exp>;
};

export type Video_Views_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Video_Views_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Video_Views_Select_Column {
  CREATED_AT = 'created_at',
  ID = 'id',
  USER_ID = 'user_id',
  VIDEO_ID = 'video_id',
}

export type Video_Views_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Video_Views_Stddev_Fields = {
  __typename: 'video_views_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Video_Views_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Video_Views_Stddev_Pop_Fields = {
  __typename: 'video_views_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Video_Views_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Video_Views_Stddev_Samp_Fields = {
  __typename: 'video_views_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Video_Views_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Video_Views_Sum_Fields = {
  __typename: 'video_views_sum_fields';
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  video_id?: Maybe<Scalars['Int']>;
};

export type Video_Views_Sum_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export enum Video_Views_Update_Column {
  CREATED_AT = 'created_at',
  ID = 'id',
  USER_ID = 'user_id',
  VIDEO_ID = 'video_id',
}

export type Video_Views_Var_Pop_Fields = {
  __typename: 'video_views_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Video_Views_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Video_Views_Var_Samp_Fields = {
  __typename: 'video_views_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Video_Views_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Video_Views_Variance_Fields = {
  __typename: 'video_views_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  video_id?: Maybe<Scalars['Float']>;
};

export type Video_Views_Variance_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  video_id?: Maybe<Order_By>;
};

export type Videos = {
  __typename: 'videos';
  amount_played: Scalars['Int'];
  answered_question_sets: Array<Answered_Question_Sets>;
  answered_question_sets_aggregate: Answered_Question_Sets_Aggregate;
  answered_questions: Array<Answered_Questions>;
  answered_questions_aggregate: Answered_Questions_Aggregate;
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  questions: Array<Questions>;
  questions_aggregate: Questions_Aggregate;
  url: Scalars['String'];
  video_ratings: Array<Video_Ratings>;
  video_ratings_aggregate: Video_Ratings_Aggregate;
  video_views: Array<Video_Views>;
  video_views_aggregate: Video_Views_Aggregate;
};

export type VideosAnswered_Question_SetsArgs = {
  distinct_on?: Maybe<Array<Answered_Question_Sets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Question_Sets_Order_By>>;
  where?: Maybe<Answered_Question_Sets_Bool_Exp>;
};

export type VideosAnswered_Question_Sets_AggregateArgs = {
  distinct_on?: Maybe<Array<Answered_Question_Sets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Question_Sets_Order_By>>;
  where?: Maybe<Answered_Question_Sets_Bool_Exp>;
};

export type VideosAnswered_QuestionsArgs = {
  distinct_on?: Maybe<Array<Answered_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Questions_Order_By>>;
  where?: Maybe<Answered_Questions_Bool_Exp>;
};

export type VideosAnswered_Questions_AggregateArgs = {
  distinct_on?: Maybe<Array<Answered_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Answered_Questions_Order_By>>;
  where?: Maybe<Answered_Questions_Bool_Exp>;
};

export type VideosQuestionsArgs = {
  distinct_on?: Maybe<Array<Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Questions_Order_By>>;
  where?: Maybe<Questions_Bool_Exp>;
};

export type VideosQuestions_AggregateArgs = {
  distinct_on?: Maybe<Array<Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Questions_Order_By>>;
  where?: Maybe<Questions_Bool_Exp>;
};

export type VideosVideo_RatingsArgs = {
  distinct_on?: Maybe<Array<Video_Ratings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Video_Ratings_Order_By>>;
  where?: Maybe<Video_Ratings_Bool_Exp>;
};

export type VideosVideo_Ratings_AggregateArgs = {
  distinct_on?: Maybe<Array<Video_Ratings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Video_Ratings_Order_By>>;
  where?: Maybe<Video_Ratings_Bool_Exp>;
};

export type VideosVideo_ViewsArgs = {
  distinct_on?: Maybe<Array<Video_Views_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Video_Views_Order_By>>;
  where?: Maybe<Video_Views_Bool_Exp>;
};

export type VideosVideo_Views_AggregateArgs = {
  distinct_on?: Maybe<Array<Video_Views_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Video_Views_Order_By>>;
  where?: Maybe<Video_Views_Bool_Exp>;
};

export type Videos_Aggregate = {
  __typename: 'videos_aggregate';
  aggregate?: Maybe<Videos_Aggregate_Fields>;
  nodes: Array<Videos>;
};

export type Videos_Aggregate_Fields = {
  __typename: 'videos_aggregate_fields';
  avg?: Maybe<Videos_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Videos_Max_Fields>;
  min?: Maybe<Videos_Min_Fields>;
  stddev?: Maybe<Videos_Stddev_Fields>;
  stddev_pop?: Maybe<Videos_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Videos_Stddev_Samp_Fields>;
  sum?: Maybe<Videos_Sum_Fields>;
  var_pop?: Maybe<Videos_Var_Pop_Fields>;
  var_samp?: Maybe<Videos_Var_Samp_Fields>;
  variance?: Maybe<Videos_Variance_Fields>;
};

export type Videos_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Videos_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type Videos_Aggregate_Order_By = {
  avg?: Maybe<Videos_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Videos_Max_Order_By>;
  min?: Maybe<Videos_Min_Order_By>;
  stddev?: Maybe<Videos_Stddev_Order_By>;
  stddev_pop?: Maybe<Videos_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Videos_Stddev_Samp_Order_By>;
  sum?: Maybe<Videos_Sum_Order_By>;
  var_pop?: Maybe<Videos_Var_Pop_Order_By>;
  var_samp?: Maybe<Videos_Var_Samp_Order_By>;
  variance?: Maybe<Videos_Variance_Order_By>;
};

export type Videos_Arr_Rel_Insert_Input = {
  data: Array<Videos_Insert_Input>;
  on_conflict?: Maybe<Videos_On_Conflict>;
};

export type Videos_Avg_Fields = {
  __typename: 'videos_avg_fields';
  amount_played?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Videos_Avg_Order_By = {
  amount_played?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

export type Videos_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Videos_Bool_Exp>>>;
  _not?: Maybe<Videos_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Videos_Bool_Exp>>>;
  amount_played?: Maybe<Int_Comparison_Exp>;
  answered_question_sets?: Maybe<Answered_Question_Sets_Bool_Exp>;
  answered_questions?: Maybe<Answered_Questions_Bool_Exp>;
  code?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  questions?: Maybe<Questions_Bool_Exp>;
  url?: Maybe<String_Comparison_Exp>;
  video_ratings?: Maybe<Video_Ratings_Bool_Exp>;
  video_views?: Maybe<Video_Views_Bool_Exp>;
};

export enum Videos_Constraint {
  VIDEO_PKEY = 'video_pkey',
}

export type Videos_Inc_Input = {
  amount_played?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type Videos_Insert_Input = {
  amount_played?: Maybe<Scalars['Int']>;
  answered_question_sets?: Maybe<Answered_Question_Sets_Arr_Rel_Insert_Input>;
  answered_questions?: Maybe<Answered_Questions_Arr_Rel_Insert_Input>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  questions?: Maybe<Questions_Arr_Rel_Insert_Input>;
  url?: Maybe<Scalars['String']>;
  video_ratings?: Maybe<Video_Ratings_Arr_Rel_Insert_Input>;
  video_views?: Maybe<Video_Views_Arr_Rel_Insert_Input>;
};

export type Videos_Max_Fields = {
  __typename: 'videos_max_fields';
  amount_played?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type Videos_Max_Order_By = {
  amount_played?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

export type Videos_Min_Fields = {
  __typename: 'videos_min_fields';
  amount_played?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type Videos_Min_Order_By = {
  amount_played?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

export type Videos_Mutation_Response = {
  __typename: 'videos_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Videos>;
};

export type Videos_Obj_Rel_Insert_Input = {
  data: Videos_Insert_Input;
  on_conflict?: Maybe<Videos_On_Conflict>;
};

export type Videos_On_Conflict = {
  constraint: Videos_Constraint;
  update_columns: Array<Videos_Update_Column>;
  where?: Maybe<Videos_Bool_Exp>;
};

export type Videos_Order_By = {
  amount_played?: Maybe<Order_By>;
  answered_question_sets_aggregate?: Maybe<Answered_Question_Sets_Aggregate_Order_By>;
  answered_questions_aggregate?: Maybe<Answered_Questions_Aggregate_Order_By>;
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  questions_aggregate?: Maybe<Questions_Aggregate_Order_By>;
  url?: Maybe<Order_By>;
  video_ratings_aggregate?: Maybe<Video_Ratings_Aggregate_Order_By>;
  video_views_aggregate?: Maybe<Video_Views_Aggregate_Order_By>;
};

export type Videos_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Videos_Select_Column {
  AMOUNT_PLAYED = 'amount_played',
  CODE = 'code',
  CREATED_AT = 'created_at',
  ID = 'id',
  URL = 'url',
}

export type Videos_Set_Input = {
  amount_played?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type Videos_Stddev_Fields = {
  __typename: 'videos_stddev_fields';
  amount_played?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Videos_Stddev_Order_By = {
  amount_played?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

export type Videos_Stddev_Pop_Fields = {
  __typename: 'videos_stddev_pop_fields';
  amount_played?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Videos_Stddev_Pop_Order_By = {
  amount_played?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

export type Videos_Stddev_Samp_Fields = {
  __typename: 'videos_stddev_samp_fields';
  amount_played?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Videos_Stddev_Samp_Order_By = {
  amount_played?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

export type Videos_Sum_Fields = {
  __typename: 'videos_sum_fields';
  amount_played?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type Videos_Sum_Order_By = {
  amount_played?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

export enum Videos_Update_Column {
  AMOUNT_PLAYED = 'amount_played',
  CODE = 'code',
  CREATED_AT = 'created_at',
  ID = 'id',
  URL = 'url',
}

export type Videos_Var_Pop_Fields = {
  __typename: 'videos_var_pop_fields';
  amount_played?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Videos_Var_Pop_Order_By = {
  amount_played?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

export type Videos_Var_Samp_Fields = {
  __typename: 'videos_var_samp_fields';
  amount_played?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Videos_Var_Samp_Order_By = {
  amount_played?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

export type Videos_Variance_Fields = {
  __typename: 'videos_variance_fields';
  amount_played?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Videos_Variance_Order_By = {
  amount_played?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

export type AnsweredQuestionBaseFragment = { __typename: 'answered_questions' } & Pick<
  Answered_Questions,
  'id' | 'score'
> & {
    question: { __typename: 'questions' } & Pick<Questions, 'type'>;
    answer: Maybe<{ __typename: 'answers' } & Pick<Answers, 'is_correct'>>;
  };

export type AnsweredQuestionSetScoreQueryVariables = {
  startDatetime?: Maybe<Scalars['timestamptz']>;
  endDatetime?: Maybe<Scalars['timestamptz']>;
};

export type AnsweredQuestionSetScoreQuery = { __typename: 'query_root' } & {
  answered_question_set_score: Array<
    { __typename: 'answered_question_set_score' } & Pick<
      Answered_Question_Set_Score,
      'high_score' | 'first_name' | 'email' | 'zip_code' | 'created_at'
    >
  >;
};

export type AnsweredQuestionSetsQueryVariables = {
  userId: Scalars['Int'];
};

export type AnsweredQuestionSetsQuery = { __typename: 'query_root' } & {
  answered_question_sets: Array<
    { __typename: 'answered_question_sets' } & Pick<Answered_Question_Sets, 'id' | 'created_at'> & {
        answered_questions: Array<{ __typename: 'answered_questions' } & AnsweredQuestionBaseFragment>;
      }
  >;
  answered_question_set_high_score: Array<
    { __typename: 'answered_question_set_high_score' } & Pick<Answered_Question_Set_High_Score, 'high_score'>
  >;
};

export type AnswerBaseFragment = { __typename: 'answers' } & Pick<Answers, 'id' | 'text' | 'is_correct'>;

export type QuestionBaseFragment = { __typename: 'questions' } & Pick<Questions, 'id' | 'tagline' | 'text' | 'type'> & {
    answers: Array<{ __typename: 'answers' } & AnswerBaseFragment>;
  };

export type QuestionsQueryVariables = {};

export type QuestionsQuery = { __typename: 'query_root' } & {
  videoQuestions: Array<{ __typename: 'questions' } & QuestionBaseFragment>;
  surveyQuestions: Array<{ __typename: 'questions' } & QuestionBaseFragment>;
  offerQuestions: Array<{ __typename: 'questions' } & QuestionBaseFragment>;
};

export type AnswerQuestionMutationVariables = {
  setId: Scalars['Int'];
  answerId?: Maybe<Scalars['Int']>;
  videoId: Scalars['Int'];
  questionId: Scalars['Int'];
  score: Scalars['Int'];
};

export type AnswerQuestionMutation = { __typename: 'mutation_root' } & {
  insert_answered_questions_one: Maybe<{ __typename: 'answered_questions' } & Pick<Answered_Questions, 'id'>>;
};

export type CreateAnsweredQuestionSetMutationVariables = {
  answerId?: Maybe<Scalars['Int']>;
  videoId: Scalars['Int'];
  questionId: Scalars['Int'];
  score: Scalars['Int'];
};

export type CreateAnsweredQuestionSetMutation = { __typename: 'mutation_root' } & {
  insert_answered_question_sets_one: Maybe<
    { __typename: 'answered_question_sets' } & Pick<Answered_Question_Sets, 'id'>
  >;
};

export type VideoBaseFragment = { __typename: 'videos' } & Pick<Videos, 'id' | 'url'>;

export type VideoQueryVariables = {
  id: Scalars['Int'];
};

export type VideoQuery = { __typename: 'query_root' } & {
  videos_by_pk: Maybe<{ __typename: 'videos' } & VideoBaseFragment>;
};

export type VideoReportQueryVariables = {
  id: Scalars['Int'];
  startDatetime?: Maybe<Scalars['timestamptz']>;
  endDatetime?: Maybe<Scalars['timestamptz']>;
};

export type VideoReportQuery = { __typename: 'query_root' } & {
  highScores: Array<
    { __typename: 'answered_question_set_high_score' } & Pick<
      Answered_Question_Set_High_Score,
      'high_score' | 'first_name' | 'email'
    >
  >;
  acceptedOffers: Array<
    { __typename: 'answered_questions' } & Pick<Answered_Questions, 'id' | 'created_at'> & {
        user: { __typename: 'users' } & Pick<Users, 'id' | 'first_name' | 'email'>;
      }
  >;
  videos_by_pk: Maybe<
    { __typename: 'videos' } & {
      totalQuestionsAnswered: { __typename: 'answered_questions_aggregate' } & {
        aggregate: Maybe<
          { __typename: 'answered_questions_aggregate_fields' } & Pick<Answered_Questions_Aggregate_Fields, 'count'>
        >;
      };
      video_views_aggregate: { __typename: 'video_views_aggregate' } & {
        aggregate: Maybe<{ __typename: 'video_views_aggregate_fields' } & Pick<Video_Views_Aggregate_Fields, 'count'>>;
      };
      totalCorrectQuestionsAnswered: { __typename: 'answered_questions_aggregate' } & {
        aggregate: Maybe<
          { __typename: 'answered_questions_aggregate_fields' } & Pick<Answered_Questions_Aggregate_Fields, 'count'>
        >;
      };
      totalUnanswered: { __typename: 'answered_questions_aggregate' } & {
        aggregate: Maybe<
          { __typename: 'answered_questions_aggregate_fields' } & Pick<Answered_Questions_Aggregate_Fields, 'count'>
        >;
      };
      video_ratings: Array<{ __typename: 'video_ratings' } & Pick<Video_Ratings, 'id' | 'created_at' | 'value'>>;
      questions: Array<
        { __typename: 'questions' } & Pick<Questions, 'id' | 'text' | 'type'> & {
            totalUnansweredCount: { __typename: 'answered_questions_aggregate' } & {
              aggregate: Maybe<
                { __typename: 'answered_questions_aggregate_fields' } & Pick<
                  Answered_Questions_Aggregate_Fields,
                  'count'
                >
              >;
            };
            totalAnsweredCount: { __typename: 'answered_questions_aggregate' } & {
              aggregate: Maybe<
                { __typename: 'answered_questions_aggregate_fields' } & Pick<
                  Answered_Questions_Aggregate_Fields,
                  'count'
                >
              >;
            };
            correctAnsweredCount: { __typename: 'answered_questions_aggregate' } & {
              aggregate: Maybe<
                { __typename: 'answered_questions_aggregate_fields' } & Pick<
                  Answered_Questions_Aggregate_Fields,
                  'count'
                >
              >;
            };
            answers: Array<
              { __typename: 'answers' } & Pick<Answers, 'id' | 'text' | 'is_correct'> & {
                  totalAnsweredCount: { __typename: 'answered_questions_aggregate' } & {
                    aggregate: Maybe<
                      { __typename: 'answered_questions_aggregate_fields' } & Pick<
                        Answered_Questions_Aggregate_Fields,
                        'count'
                      >
                    >;
                  };
                }
            >;
          }
      >;
    } & VideoBaseFragment
  >;
};

export type CreateVideoViewMutationVariables = {
  videoId: Scalars['Int'];
};

export type CreateVideoViewMutation = { __typename: 'mutation_root' } & {
  insert_video_views_one: Maybe<{ __typename: 'video_views' } & Pick<Video_Views, 'id'>>;
};

export type LoginMutationVariables = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginMutation = { __typename: 'mutation_root' } & {
  login: { __typename: 'AuthOutput' } & Pick<AuthOutput, 'accessToken'>;
};

export type CreateUnknownUserMutationVariables = {};

export type CreateUnknownUserMutation = { __typename: 'mutation_root' } & {
  createUnknownUser: { __typename: 'AuthOutput' } & Pick<AuthOutput, 'accessToken'>;
};

export type RateVideoMutationVariables = {
  value: Scalars['Int'];
  videoId: Scalars['Int'];
};

export type RateVideoMutation = { __typename: 'mutation_root' } & {
  insert_video_ratings_one: Maybe<{ __typename: 'video_ratings' } & Pick<Video_Ratings, 'id'>>;
};

export type RegisterMutationVariables = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  zipCode?: Maybe<Scalars['String']>;
};

export type RegisterMutation = { __typename: 'mutation_root' } & {
  register: { __typename: 'AuthOutput' } & Pick<AuthOutput, 'accessToken'>;
};

export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}
const result: IntrospectionResultData = {
  __schema: {
    types: [],
  },
};
export default result;
