import { useMutation } from '@apollo/react-hooks';
import { useFormik } from 'formik';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { resetAuthContext } from '../../libs/authentication';
import { LoginMutation, LoginMutationVariables } from '../../types/graphql-generated';

type FormValues = {
  email: string;
  password: string;
};

function AdminLoginPage({ redirectPath }: { redirectPath?: string }) {
  const history = useHistory();
  const [formError, setFormError] = useState('');

  React.useEffect(() => {
    resetAuthContext();
  }, []);

  const { handleSubmit, values, setFieldValue, isValid } = useFormik<FormValues>({
    initialValues: {
      email: '',
      password: '',
    },
    isInitialValid: false,
    onSubmit,
    validationSchema,
  });

  const [login] = useMutation<LoginMutation, LoginMutationVariables>(LOGIN);

  return (
    <div className="bg-cool-gray-100 items-center justify-center flex flex-1">
      <div className="flex flex-col items-center">
        <h3 className="text-gray-700 font-semibold text-xl">Log In</h3>
        <form onSubmit={handleSubmit}>
          <div className="mt-2">
            <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
              Email
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                value={values.email}
                onChange={event => setFieldValue('email', event.target.value)}
                id="email"
                className="form-input block w-full sm:text-sm sm:leading-5"
                placeholder="you@example.com"
              />
            </div>
          </div>
          <div className="mt-2">
            <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
              Password
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="password"
                value={values.password}
                onChange={event => setFieldValue('password', event.target.value)}
                id="password"
                className="form-input block w-full sm:text-sm sm:leading-5"
                placeholder="*********"
              />
            </div>
          </div>

          <button
            disabled={!isValid}
            type="submit"
            className="items-center disabled:bg-gray-400 mt-2 px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
          >
            Login
          </button>

          {formError && (
            <div className="mt-3">
              <p className="text-sm text-red-700">{formError}</p>
            </div>
          )}
        </form>
      </div>
    </div>
  );

  async function onSubmit(formValues: FormValues) {
    try {
      const mutationResult = await login({ variables: formValues });

      if (mutationResult.data) {
        const { accessToken } = mutationResult.data.login;

        localStorage.setItem('ACCESS_TOKEN', accessToken);

        if (redirectPath) {
          history.push(redirectPath);
        } else {
          history.push('/reports');
        }
      }
    } catch ({ graphQLErrors }) {
      const { message } = graphQLErrors[0];

      if (message === 'NO_USER_FOUND_FOR_CREDENTIALS') {
        setFormError('No user was found for this email and password combination.');
      }
    }
  }
}

const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
    }
  }
`;

export default AdminLoginPage;

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Please provide a valid email address.')
    .required('Please provide a valid email address.'),
  password: Yup.string()
    .required('Please provide a password.')
    .min(6, 'Your password must contain at least 6 characters.'),
});
